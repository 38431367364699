import Vue from 'vue'
import { ValidationProvider, extend, ValidationObserver, localize } from 'vee-validate'
import id from 'vee-validate/dist/locale/id.json'
import * as rules from 'vee-validate/dist/rules'

Object.keys(rules).forEach(
  rule => { 
    extend(rule, rules[rule]) 
  }
)

extend('greater_than_start', {
  params: ['start'], // Declare start as a parameter
  validate(value, { start }) {
    const startTime = new Date(`1970-01-01T${start}:00`)
    const endTime = new Date(`1970-01-01T${value}:00`)

    return endTime > startTime
  },
  message: 'Jam Selesai harus lebih besar dari Jam Mulai',
})
localize('id', id)


Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)