const bo_routes = [
  {
    path: '',
    redirect: { name: 'Login' },
  },
  {
    path: 'login',
    name: 'Login',
    component: () => import('@/views/backend/Login.vue'),
    meta: {
      redirectIfLoggedIn: true,
      layout: 'empty',
    }
  },

  // pendaftaran online
  {
    path: 'display-poli',
    name: 'DisplayPoli',
    component: () => import('@/views/display/DisplayPoli.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-penunjang',
    name: 'DisplayLab',
    component: () => import('@/views/display/DisplayLab.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-antrian',
    name: 'DisplayAntrian',
    component: () => import('@/views/display/DisplayAntrian.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-cetak',
    name: 'DisplayCetakAntrian',
    component: () => import('@/views/display/DisplayCetakAntrian.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-checkin',
    name: 'DisplayCheckin',
    component: () => import('@/views/display/DisplayCheckin.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-finger-print',
    name: 'DisplayFingerPrint',
    component: () => import('@/views/display/DisplayFingerPrint.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-antrian-admisi',
    name: 'DisplayAntrianAdmisi',
    component: () => import('@/views/display/DisplayAntrianAdmisi.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-antrian-perawat',
    name: 'DisplayAntrianPerawat',
    component: () => import('@/views/display/DisplayAntrianPerawat.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-antrian-farmasi',
    name: 'DisplayAntrianFarmasi',
    component: () => import('@/views/display/DisplayAntrianFarmasi.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-antrian-farmasi-ugd',
    name: 'DisplayAntrianFarmasiUGD',
    component: () => import('@/views/display/DisplayAntrianFarmasiUGD.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-check-in',
    name: 'DisplayCheckin',
    component: () => import('@/views/display/DisplayCheckin.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'input-kode-booking',
    name: 'InputKodeBooking',
    component: () => import('@/views/display/InputKodeBooking.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'scan-qr-code',
    name: 'ScanQRCode',
    component: () => import('@/views/display/ScanQRCode.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'done-checkin/:pageSlug?',
    name: 'DoneCheckin',
    component: () => import('@/views/display/DoneCheckin.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'input-kode-bpjs',
    name: 'InputKodeBPJS',
    component: () => import('@/views/display/InputKodeBPJS.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  // pendaftaran online
  

  {
    path: 'dashboard/:pageSlug?',
    name: 'Dashboard',
    component: () => import('@/views/backend/Dashboard/Dashboard.vue'),
    meta: {
      menu: 'Dashboard',
      pageTitle: 'Dashboard',
      objName: 'Dashboard',
    },
  },
  {
    path: 'hais/:pageSlug?',
    name: 'Hais',
    component: () => import('@/views/backend/Hais/List.vue'),
    meta: {
      menu: 'Hais',
      pageTitle: 'Hais',
      objName: 'Hais',
    },
  },
  {
    path: 'keselamatan-pasien/:pageSlug?',
    name: 'KeselamatanPasien',
    component: () => import('@/views/backend/KeselamatanPasien/List.vue'),
    meta: {
      menu: 'KeselamatanPasien',
      pageTitle: 'Resiko Jatuh',
      objName: 'Resiko Jatuh',
    },
  },
  {
    path: 'laporan-insiden/:pageSlug?',
    name: 'LaporanInsiden',
    component: () => import('@/views/backend/LaporanInsiden/List.vue'),
    meta: {
      menu: 'LaporanInsiden',
      pageTitle: 'Laporan Insiden',
      objName: 'Laporan Insiden',
    },
  },
  {
    path: 'dokter/:pageSlug?/:pageId?',
    name: 'Dokter',
    component: () => import('@/views/backend/Dokter/List.vue'),
    meta: {
      menu: 'Dokter',
      pageTitle: 'Dokter',
      objName: 'Dokter',
    },
  },

  // {
  //   path: 'pasien/:pageSlug?',
  //   name: 'Pasien',
  //   component: () => import('@/views/backend/Pasien/List.vue'),
  //   meta: {
  //     menu: 'Pasien',
  //     pageTitle: 'Pasien',
  //     objName: 'Pasien',
  //   },
  // },

  {
    path: 'rekam-medis/:pageSlug?/:rmNo?/:typeKajian?',
    name: 'RekamMedis',
    component: () => import('@/views/backend/RekamMedis/List.vue'),
    meta: {
      menu: 'RekamMedis',
      pageTitle: 'Rekam Medis',
      objName: 'Rekam Medis',
    },
  },
  {
    path: 'registrasi-pasien/:pageSlug?',
    name: 'RegistrasiPasien',
    component: () => import('@/views/backend/RegistrasiPasien/List.vue'),
    meta: {
      menu: 'RegistrasiPasien',
      pageTitle: 'Registrasi Pasien',
      objName: 'Registrasi Pasien',
    },
  },
  {
    path: 'farmasi/:pageSlug?',
    name: 'Farmasi',
    component: () => import('@/views/backend/Farmasi/List.vue'),
    meta: {
      menu: 'Farmasi',
      pageTitle: 'Farmasi',
      objName: 'Farmasi',
    },
  },
  {
    path: 'verifikasi-rekam-medis/:pageSlug?',
    name: 'VerifikasiRM',
    component: () => import('@/views/backend/VerifikasiRM/Form.vue'),
    meta: {
      menu: 'VerifikasiRM',
      pageTitle: 'Verifikasi Rekam Medis',
      objName: 'Verifikasi Rekam Medis',
    },
  }, {
    path: 'verifikasi-upla/:pageSlug?',
    name: 'VerifikasiUpla',
    component: () => import('@/views/backend/VerifikasiUpla/Form.vue'),
    meta: {
      menu: 'VerifikasiUpla',
      pageTitle: 'Verifikasi Upla',
      objName: 'Verifikasi Upla',
    },
  },
  {
    path: 'laporan-management/:pageSlug?',
    name: 'LaporanManagement',
    component: () => import('@/views/backend/LaporanManagement/List.vue'),
    meta: {
      menu: 'LaporanManagement',
      pageTitle: 'Laporan Management',
      objName: 'Laporan Management',
    },
  },
  {
    path: 'master-hais/:pageSlug?',
    name: 'MHais',
    component: () => import('@/views/backend/MHais/List.vue'),
    meta: {
      menu: "MHais",
      pageTitle: 'Master Hais',
      hasCta: 'true',
      objName: 'Master Hais'
    },
  },
  {
    path: 'master-dosis/:pageSlug?',
    name: 'MDosis',
    component: () => import('@/views/backend/MDosis/List.vue'),
    meta: {
      menu: "MDosis",
      pageTitle: 'Master Dosis',
      hasCta: 'true',
      objName: 'Master Dosis'
    },
  },
  {
    path: 'follow-up/:pageSlug?',
    name: 'FollowUp',
    component: () => import('@/views/backend/FollowUp/Form.vue'),
    meta: {
      menu: "FollowUp",
      pageTitle: 'Follow Up',
      objName: 'Follow Up'
    },
  },
  {
    path: 'master-poli/:pageSlug?',
    name: 'MPoli',
    component: () => import('@/views/backend/MPoli/List.vue'),
    meta: {
      menu: "MPoli",
      pageTitle: 'Master Poli',
      hasCta: 'true',
      objName: 'Master Poli'
    },
  }, 
  {
    path: 'master-obat/:pageSlug?',
    name: 'MObat',
    component: () => import('@/views/backend/MObat/List.vue'),
    meta: {
      menu: "MObat",
      pageTitle: 'Master Obat',
      hasCta: 'true',
      objName: 'Master Obat'
    },
  },
  {
    path: 'master-alat-kesehatan/:pageSlug?',
    name: 'MAlatKesehatan',
    component: () => import('@/views/backend/MAlatKesehatan/List.vue'),
    meta: {
      menu: "MAlatKesehatan",
      pageTitle: 'Master Alat Kesehatan',
      hasCta: 'true',
      objName: 'Master Alat Kesehatan'
    },
  },
  {
    path: 'master-radiologi/:pageSlug?',
    name: 'MRadiologi',
    component: () => import('@/views/backend/MRadiologi/List.vue'),
    meta: {
      menu: "MRadiologi",
      pageTitle: 'Master Radiologi',
      hasCta: 'true',
      objName: 'Master Radiologi'
    },
  },
  {
    path: 'master-insiden/:pageSlug?',
    name: 'MInsiden',
    component: () => import('@/views/backend/MInsiden/List.vue'),
    meta: {
      menu: "MInsiden",
      pageTitle: 'Master Insiden',
      hasCta: 'true',
      objName: 'Master Insiden'
    },
  },
  {
    path: 'master-zipcode/:pageSlug?',
    name: 'MZipCode',
    component: () => import('@/views/backend/MZipCode/List.vue'),
    meta: {
      menu: "MZipCode",
      pageTitle: 'Master Zip Code',
      hasCta: 'true',
      objName: 'Master Zip Code'
    },
  },
  {
    path: 'master-diagnosa-gizi/:pageSlug?',
    name: 'MICDGizi',
    component: () => import('@/views/backend/MICDGizi/List.vue'),
    meta: {
      menu: "MICDGizi",
      pageTitle: 'Master Diagnosa Gizi',
      hasCta: 'true',
      objName: 'Master Diagnosa Gizi'
    },
  },
  {
    path: 'master-tindakan-gizi/:pageSlug?',
    name: 'MICDGiziTindakan',
    component: () => import('@/views/backend/MICDGiziTindakan/List.vue'),
    meta: {
      menu: "MICDGiziTindakan",
      pageTitle: 'Master Intervensi Gizi',
      hasCta: 'true',
      objName: 'Master Intervensi Gizi'
    },
  },
  {
    path: 'master-icd-9/:pageSlug?',
    name: 'MICD9',
    component: () => import('@/views/backend/MICD9/List.vue'),
    meta: {
      menu: "MICD9",
      pageTitle: 'Master ICD9',
      hasCta: 'true',
      objName: 'Master ICD9'
    },
  }, 
  {
    path: 'master-icd-10/:pageSlug?',
    name: 'MICD10',
    component: () => import('@/views/backend/MICD10/List.vue'),
    meta: {
      menu: "MICD10",
      pageTitle: 'Master ICD10',
      hasCta: 'true',
      objName: 'Master ICD10'
    },
  },
  
  {
    path: 'master-icd-9/:pageSlug?',
    name: 'MICD9UPLA',
    component: () => import('@/views/backend/MICD9UPLA/List.vue'),
    meta: {
      menu: "MICD9UPLA",
      pageTitle: 'Master ICD9 UPLA',
      hasCta: 'true',
      objName: 'Master ICD9 UPLA'
    },
  }, 
  {
    path: 'master-icd-10/:pageSlug?',
    name: 'MICD10UPLA',
    component: () => import('@/views/backend/MICD10UPLA/List.vue'),
    meta: {
      menu: "MICD10UPLA",
      pageTitle: 'Master ICD10 UPLA',
      hasCta: 'true',
      objName: 'Master ICD10 UPLA'
    },
  },

  {
    path: 'master-alat-bantu-tarik/:pageSlug?',
    name: 'MAlatBantuTarik',
    component: () => import('@/views/backend/MAlatBantuTarik/List.vue'),
    meta: {
      menu: "MAlatBantuTarik",
      pageTitle: 'Master Alat Bantu Tarik',
      hasCta: 'true',
      objName: 'Master Alat Bantu Tarik'
    },
  }, 
  {
    path: 'master-tindakan-fisioterapi/:pageSlug?',
    name: 'MTindakan',
    component: () => import('@/views/backend/MTindakan/List.vue'),
    meta: {
      menu: "MTindakan",
      pageTitle: 'Master Tindakan Fisioterapi',
      hasCta: 'true',
      objName: 'Master Tindakan Fisioterapi'
    },
  }, 
  {
    path: 'master-cara-pembayaran/:pageSlug?',
    name: 'MCaraPembayaran',
    component: () => import('@/views/backend/MCaraPembayaran/List.vue'),
    meta: {
      menu: "MCaraPembayaran",
      pageTitle: 'Master Cara Pembayaran',
      hasCta: 'true',
      objName: 'Master Cara Pembayaran'
    },
  },
  {
    path: 'master-kesadaran/:pageSlug?',
    name: 'MKesadaran',
    component: () => import('@/views/backend/MKesadaran/List.vue'),
    meta: {
      menu: "MKesadaran",
      pageTitle: 'Master Kesadaran',
      hasCta: 'true',
      objName: 'Master Kesadaran'
    },
  },
  {
    path: 'master-pekerjaan/:pageSlug?',
    name: 'MPendidikan',
    component: () => import('@/views/backend/MPendidikan/List.vue'),
    meta: {
      menu: "MPendidikan",
      pageTitle: 'Master Pendidikan',
      hasCta: 'true',
      objName: 'Master Pendidikan'
    },
  },
  {
    path: 'master-pekerjaan/:pageSlug?',
    name: 'MPekerjaan',
    component: () => import('@/views/backend/MPekerjaan/List.vue'),
    meta: {
      menu: "MPekerjaan",
      pageTitle: 'Master Pekerjaan',
      hasCta: 'true',
      objName: 'Master Pekerjaan'
    },
  },
  {
    path: 'master-laboratorium/:pageSlug?',
    name: 'MLab',
    component: () => import('@/views/backend/MLab/List.vue'),
    meta: {
      menu: "MLab",
      pageTitle: 'Master Tindakan Laboratorium',
      hasCta: 'true',
      objName: 'Master Tindakan Laboratorium'
    },
  },
  {
    path: 'master-sub-laboratorium/:pageSlug?',
    name: 'MSubLab',
    component: () => import('@/views/backend/MSubLab/List.vue'),
    meta: {
      menu: "MSubLab",
      pageTitle: 'Master Sub Laboratorium',
      hasCta: 'true',
      objName: 'Master Sub Laboratorium'
    },
  },
  {
    path: 'master-agama/:pageSlug?',
    name: 'MAgama',
    component: () => import('@/views/backend/MAgama/List.vue'),
    meta: {
      menu: "MAgama",
      pageTitle: 'Master Agama',
      hasCta: 'true',
      objName: 'Master Agama'
    },
  },
  {
    path: 'master-sdki/:pageSlug?',
    name: 'MSDKI',
    component: () => import('@/views/backend/MSDKI/List.vue'),
    meta: {
      menu: "MSDKI",
      pageTitle: 'Master Diagnosa SDKI',
      hasCta: 'true',
      objName: 'Master Diagnosa SDKI'
    },
  },
  {
    path: 'master-kategori-sdki/:pageSlug?',
    name: 'MKategoriSDKI',
    component: () => import('@/views/backend/MKategoriSDKI/List.vue'),
    meta: {
      menu: "MKategoriSDKI",
      pageTitle: 'Master Kategori SDKI',
      hasCta: 'true',
      objName: 'Master Kategori SDKI'
    },
  },
  {
    path: 'master-sub-kategori-sdki/:pageSlug?',
    name: 'MSubKategoriSDKI',
    component: () => import('@/views/backend/MSubKategoriSDKI/List.vue'),
    meta: {
      menu: "MSubKategoriSDKI",
      pageTitle: 'Master Sub Kategori SDKI',
      hasCta: 'true',
      objName: 'Master Sub Kategori SDKI'
    },
  },
  {
    path: 'master-slki/:pageSlug?',
    name: 'MSLKI',
    component: () => import('@/views/backend/MSLKI/List.vue'),
    meta: {
      menu: "MSLKI",
      pageTitle: 'Master Diagnosa SLKI',
      hasCta: 'true',
      objName: 'Master Diagnosa SLKI'
    },
  },
  {
    path: 'master-kriteria-hasil-slki/:pageSlug?',
    name: 'MKriteriaSLKI',
    component: () => import('@/views/backend/MKriteriaSLKI/List.vue'),
    meta: {
      menu: "MKriteriaSLKI",
      pageTitle: 'Master Kriteria SLKI',
      hasCta: 'true',
      objName: 'Master Kriteria SLKI'
    },
  },
  {
    path: 'master-siki/:pageSlug?',
    name: 'MSIKI',
    component: () => import('@/views/backend/MSIKI/List.vue'),
    meta: {
      menu: "MSIKI",
      pageTitle: 'Master SIKI',
      hasCta: 'true',
      objName: 'Master SIKI'
    },
  },
  {
    path: 'master-type-satuan/:pageSlug?',
    name: 'MTypeSatuan',
    component: () => import('@/views/backend/MTypeSatuan/List.vue'),
    meta: {
      menu: "MTypeSatuan",
      pageTitle: 'Master Type Satuan',
      hasCta: 'true',
      objName: 'Master Type Satuan'
    },
  },
  {
    path: 'master-fakes-rujukan/:pageSlug?',
    name: 'MRoFakesRujukan',
    component: () => import('@/views/backend/MRoFakesRujukan/List.vue'),
    meta: {
      menu: "MRoFakesRujukan",
      pageTitle: 'Master Fakes Rujukan',
      hasCta: 'true',
      objName: 'Master Fakes Rujukan'
    },
  },
  {
    path: 'master-poli-rujukan/:pageSlug?',
    name: 'MRoPoliRujukan',
    component: () => import('@/views/backend/MRoPoliRujukan/List.vue'),
    meta: {
      menu: "MRoPoliRujukan",
      pageTitle: 'Master Poli Rujukan',
      hasCta: 'true',
      objName: 'Master Poli Rujukan'
    },
  },
  {
    path: 'master-dokter-rujukan/:pageSlug?',
    name: 'MRoDokterRujukan',
    component: () => import('@/views/backend/MRoDokterRujukan/List.vue'),
    meta: {
      menu: "MRoDokterRujukan",
      pageTitle: 'Master Dokter Rujukan',
      hasCta: 'true',
      objName: 'Master Dokter Rujukan'
    },
  },
  {
    path: 'master-suku/:pageSlug?',
    name: 'MRoSuku',
    component: () => import('@/views/backend/MRoSuku/List.vue'),
    meta: {
      menu: "MRoSuku",
      pageTitle: 'Master Suku',
      hasCta: 'true',
      objName: 'Master Suku'
    },
  },
  {
    path: 'master-hari-libur',
    name: 'MHariLibur',
    component: () => import('@/views/backend/MHariLibur/List.vue'),
    meta: {
      menu: "MHariLibur",
      pageTitle: 'Master Hari Libur',
      objName: 'Master Hari Libur'
    },
  },
  {
    path: 'master-riwayat-alergi/:pageSlug?',
    name: 'MRiwayatAlergi',
    component: () => import('@/views/backend/MRiwayatAlergi/List.vue'),
    meta: {
      menu: "MRiwayatAlergi",
      pageTitle: 'Master Riwayat Alergi',
      hasCta: 'true',
      objName: 'Master Riwayat Alergi'
    },
  },

  {
    path: 'account-setting',
    name: 'AccountSetting',
    component: () => import('@/views/backend/AccountSetting.vue'),
    meta: {
      menu: 'BoAccount',
      pageTitle: 'Account Setting',
      objName: 'Account',      
    },
  },
  {
    path: 'user/:pageSlug?',
    name: 'Users',
    component: () => import('@/views/backend/User/List.vue'),
    meta: {
      menu: "Users",
      pageTitle: 'Users',
      hasCta: 'true',
      objName: 'User'
    },
  },
  {
    path: 'user-level/:pageSlug?',
    name: 'UserLevel',
    component: () => import('@/views/backend/UserLevel/List.vue'),
    meta: {
      menu: 'UserLevel',
      pageTitle: 'User Level',
      hasCta: 'true',
      objName: 'User Level'
    },
  },
  {
    path: 'audit-trail/:pageSlug?',
    name: 'AuditTrail',
    component: () => import('@/views/backend/AuditTrail/AuditTrail.vue'),
    meta: {
      menu: 'AuditTrail',
      pageTitle: 'Audit Trail',
      objName: 'Audit Trail'
    },
  },

  {
    path: 'pasien/:pageSlug?',
    name: 'RoPasien',
    component: () => import('@/views/backend/RoPasien/List.vue'),
    meta: {
      menu: 'RoPasien',
      pageTitle: 'Admisi - Pasien',
      objName: 'Admisi - Pasien',
    },
  },

  {
    path: 'admisi-reservasi/:pageSlug?',
    name: 'RoReservasi',
    component: () => import('@/views/backend/RoReservasi/List.vue'),
    meta: {
      menu: 'RoReservasi',
      pageTitle: 'Admisi - Reservasi',
      objName: 'Admisi - Reservasi'
    },
  },

  {
    path: 'kasir',
    name: 'RoKasir',
    component: () => import('@/views/backend/RoKasir/List.vue'),
    meta: {
      menu: 'RoKasir',
      pageTitle: 'Kasir',
      objName: 'Kasir'
    },
  },
  
  {
    path: 'audio-antrean/:pageSlug?',
    name: 'AntreanRoAudio',
    component: () => import('@/views/backend/AntreanRoAudio/List.vue'),
    meta: {
      menu: 'AntreanRoAudio',
      pageTitle: 'Antrean - Audio',
      objName: 'Antrean - Audio',
      hasCta: 'true',
    },
  },
  {
    path: 'unit-tujuan/:pageSlug?',
    name: 'AntreanRoUnit',
    component: () => import('@/views/backend/AntreanRoUnit/List.vue'),
    meta: {
      menu: 'AntreanRoUnit',
      pageTitle: 'Antrean - Unit Tujuan',
      objName: 'Antrean - Unit Tujuan',
      hasCta: 'true',
    },
  },
  {
    path: 'group-antrean/:pageSlug?',
    name: 'AntreanRoGroup',
    component: () => import('@/views/backend/AntreanRoGroup/List.vue'),
    meta: {
      menu: 'AntreanRoGroup',
      pageTitle: 'Antrean - Group Antrean',
      objName: 'Antrean - Group Antrean',
      hasCta: 'true',
    },
  },
  {
    path: 'display-layar/:pageSlug?',
    name: 'AntreanRoDisplayLayar',
    component: () => import('@/views/backend/AntreanRoDisplayLayar/List.vue'),
    meta: {
      menu: 'AntreanRoDisplayLayar',
      pageTitle: 'Antrean - Display Antrean',
      objName: 'Antrean - Display Antrean',
    },
  },
  {
    path: 'jadwal-dokter',
    name: 'RoJadwalDokter',
    component: () => import('@/views/backend/RoJadwalDokter/List.vue'),
    meta: {
      menu: 'RoJadwalDokter',
      pageTitle: 'Jadwal Dokter',
      objName: 'Jadwal Dokter',
    },
  },
  {
    path: 'rujukan/:pageSlug?',
    name: 'RoRujukan',
    component: () => import('@/views/backend/RoRujukan/List.vue'),
    meta: {
      menu: 'RoRujukan',
      pageTitle: 'Rujukan',
      objName: 'Rujukan'
    },
  },
  {
    path: 'rencana-kontrol/:pageSlug?',
    name: 'RoRencanaKontrol',
    component: () => import('@/views/backend/RoRencanaKontrol/List.vue'),
    meta: {
      menu: 'RoRencanaKontrol',
      pageTitle: 'Rencana Kontrol',
      objName: 'Rencana Kontrol'
    },
  },
  {
    path: 'admisi-antrean',
    name: 'RoAdmisiAntrean',
    component: () => import('@/views/backend/RoAdmisiAntrean/List.vue'),
    meta: {
      menu: 'RoAdmisiAntrean',
      pageTitle: 'Admisi Antrean',
      objName: 'Admisi Antrean',
    },
  },
  {
    path: 'billing',
    name: 'RoBilling',
    component: () => import('@/views/backend/RoBilling/List.vue'),
    meta: {
      menu: 'RoBilling',
      pageTitle: 'Billing',
      objName: 'Billing',
    },
  },
  {
    path: 'Notifikasi',
    name: 'Notification',
    component: () => import('@/views/backend/Notification/List.vue'),
    meta: {
      menu: 'Notification',
      pageTitle: 'Notifikasi',
      objName: 'Notifikasi',
    },
  },

  {
    path: 'bpjs-peserta',
    name: 'BPJSPeserta',
    component: () => import('@/views/backend/BPJSPeserta/List.vue'),
    meta: {
      menu: 'BPJSPeserta',
      pageTitle: 'BPJS Peserta',
      objName: 'BPJS Peserta',
    },
  },

  {
    path: 'bpjs-referensi',
    name: 'BPJSReferensi',
    component: () => import('@/views/backend/BPJSReferensi/List.vue'),
    meta: {
      menu: 'BPJSReferensi',
      pageTitle: 'BPJS Referensi',
      objName: 'BPJS Referensi',
    },
  },

  {
    path: 'bpjs-log-antrean',
    name: 'BPJSLogAntrean',
    component: () => import('@/views/backend/BPJSLogAntrean/List.vue'),
    meta: {
      menu: 'BPJSLogAntrean',
      pageTitle: 'BPJS Log Antrean',
      objName: 'BPJS Log Antrean',
    },
  },

  {
    path: 'bpjs-monitoring',
    name: 'BPJSMonitoring',
    component: () => import('@/views/backend/BPJSMonitoring/List.vue'),
    meta: {
      menu: 'BPJSMonitoring',
      pageTitle: 'BPJS Monitoring',
      objName: 'BPJS Monitoring',
    },
  },

  {
    path: 'bpjs-sep',
    name: 'BPJSSEP',
    component: () => import('@/views/backend/BPJSSEP/List.vue'),
    meta: {
      menu: 'BPJSSEP',
      pageTitle: 'BPJS SEP',
      objName: 'BPJS SEP',
    },
  },

  {
    path: 'bpjs-referensi-antrean',
    name: 'BPJSRefrensiAntrean',
    component: () => import('@/views/backend/BPJSRefrensiAntrean/List.vue'),
    meta: {
      menu: 'BPJSRefrensiAntrean',
      pageTitle: 'BPJS Referensi Antrean',
      objName: 'BPJS Referensi Antrean',
    },
  },
  
  {
    path: 'bpjs-rujukan',
    name: 'BPJSRujukan',
    component: () => import('@/views/backend/BPJSRujukan/List.vue'),
    meta: {
      menu: 'BPJSRujukan',
      pageTitle: 'BPJS Rujukan',
      objName: 'BPJS Rujukan',
    },
  },

  {
    path: 'bpjs-prb',
    name: 'BPJSPRB',
    component: () => import('@/views/backend/BPJSPRB/List.vue'),
    meta: {
      menu: 'BPJSPRB',
      pageTitle: 'BPJS PRB',
      objName: 'BPJS PRB',
    },
  },

  {
    path: 'bpjs-rencana-kontrol',
    name: 'BPJSRencanaKontrol',
    component: () => import('@/views/backend/BPJSRencanaKontrol/List.vue'),
    meta: {
      menu: 'BPJSRencanaKontrol',
      pageTitle: 'BPJS Rencana Kontrol',
      objName: 'BPJS Rencana Kontrol',
    },
  },

  {
    path: 'bpjs-data-sep/:pageSlug?',
    name: 'BPJSDataSEP',
    component: () => import('@/views/backend/BPJSDataSEP/List.vue'),
    meta: {
      menu: 'BPJSDataSEP',
      pageTitle: 'Data SEP',
      objName: 'Data SEP',
      hasCta: 'true',
    },
  },

  {
    path: 'bpjs-lpk/:pageSlug?',
    name: 'BPJSLPK',
    component: () => import('@/views/backend/BPJSLPK/List.vue'),
    meta: {
      menu: 'BPJSLPK',
      pageTitle: 'BPJS Data LPK',
      objName: 'BPJS Data LPK',
      hasCta: 'true',
    },
  },


  {
    path: 'report-admisi/:pageSlug?',
    name: 'RoReportAdmisi',
    component: () => import('@/views/backend/RoReportAdmisi/List.vue'),
    meta: {
      menu: 'RoReportAdmisi',
      pageTitle: 'Report Admisi',
      objName: 'Report Admisi'
    },
  },

  {
    path: 'task-id-harian/:pageSlug?',
    name: 'RoTaskIDHarian',
    component: () => import('@/views/backend/RoTaskIDHarian/List.vue'),
    meta: {
      menu: 'RoTaskIDHarian',
      pageTitle: 'Task ID Harian',
      objName: 'Task ID Harian',
    },
  },

  {
    path: 'belum-dilayani/:pageSlug?',
    name: 'RoBelumDilayani',
    component: () => import('@/views/backend/RoBelumDilayani/List.vue'),
    meta: {
      menu: 'RoBelumDilayani',
      pageTitle: 'Aktifitas Antrean',
      objName: 'Aktifitas Antrean',
    },
  },
  {
    path: 'jadwal-operasi/:pageSlug?',
    name: 'AppRanapJadwalOperasi',
    component: () => import('@/views/backend/AppRanapJadwalOperasi/List.vue'),
    meta: {
      menu: 'AppRanapJadwalOperasi',
      pageTitle: 'Jadwal Operasi',
      objName: 'Jadwal Operasi',
      hasCta: 'true',
    },
  },
  {
    path: 'm-ruangan/:pageSlug?',
    name: 'MRuangan',
    component: () => import('@/views/backend/MRuangan/List.vue'),
    meta: {
      menu: 'MRuangan',
      pageTitle: 'Ruangan',
      objName: 'Ruangan',
      hasCta: 'true',
    },
  }, {
    path: 'ugd-rumah-sakit/:pageSlug?',
    name: 'MUGDRS',
    component: () => import('@/views/backend/MUGDRS/List.vue'),
    meta: {
      menu: 'MUGDRS',
      pageTitle: 'Asal Rujukan',
      objName: 'Asal Rujukan',
      hasCta: 'true',
    },
  }, {
    path: 'ugd-puskesmas/:pageSlug?',
    name: 'MUGDPuskesmas',
    component: () => import('@/views/backend/MUGDPuskesmas/List.vue'),
    meta: {
      menu: 'MUGDPuskesmas',
      pageTitle: 'UGD Puskesmas',
      objName: 'UGD Puskesmas',
      hasCta: 'true',
    },
  }, {
    path: 'ketersediaan-kamar/:pageSlug?',
    name: 'BPJSKetersediaanKamar',
    component: () => import('@/views/backend/BPJSKetersediaanKamar/List.vue'),
    meta: {
      menu: 'BPJSKetersediaanKamar',
      pageTitle: 'Ketersediaan Kamar',
      objName: 'Ketersediaan Kamar',
    },
  },

  




  

  // UGD
  {
    path: 'pendaftaran-ugd/:pageSlug?',
    name: 'UGDReservasi',
    component: () => import('@/views/backend/UGDReservasi/List.vue'),
    meta: {
      menu: 'UGDReservasi',
      pageTitle: 'Pendaftaran UGD',
      objName: 'Pendaftaran UGD'
    },
  },
  {
    path: 'triase-ugd/:pageSlug?',
    name: 'UGDTriase',
    component: () => import('@/views/backend/UGDTriase/List.vue'),
    meta: {
      menu: 'UGDTriase',
      pageTitle: 'Triase UGD',
      objName: 'Triase UGD'
    },
  },

  {
    path: 'assesment-ugd-dokter',
    name: 'UGDAssesmentDokter',
    component: () => import('@/views/backend/UGDAssesmentDokter/List.vue'),
    meta: {
      menu: 'UGDAssesmentDokter',
      pageTitle: 'Kajian Dokter UGD',
      objName: 'Kajian Dokter UGD'
    },
  },
  
  {
    path: 'assesment-ugd-perawat',
    name: 'UGDAssesmentPerawat',
    component: () => import('@/views/backend/UGDAssesmentPerawat/List.vue'),
    meta: {
      menu: 'UGDAssesmentPerawat',
      pageTitle: 'Kajian Perawat UGD',
      objName: 'Kajian Perawat UGD'
    },
  },

  {
    path: 'list-assesment/:pageSlug?',
    name: 'UGDAssesment',
    component: () => import('@/views/backend/UGDAssesment/List.vue'),
    meta: {
      menu: 'UGDAssesment',
      pageTitle: 'Form Assesmen',
      objName: 'Form Assesmen',
    },
  },

  {
    path: 'ugd-form-perawat/:pageSlug?/:rmNo?',
    name: 'UGDFormPerawat',
    component: () => import('@/views/backend/UGDFormPerawat/Form.vue'),
    meta: {
      menu: 'UGDFormPerawat',
      pageTitle: 'Form Assesmen Perawat',
      objName: 'Form Assesmen Perawat',
    },
  },

  {
    path: 'ugd-form-dokter/:pageSlug?/:rmNo?',
    name: 'UGDFormDokter',
    component: () => import('@/views/backend/UGDFormDokter/Form.vue'),
    meta: {
      menu: 'UGDFormDokter',
      pageTitle: 'Form Assesmen Dokter',
      objName: 'Form Assesmen Dokter',
    },
  },

  {
    path: 'ugd-tindak-lanjut/:pageSlug?/:typeDokumen?',
    name: 'UGDTindakLanjut',
    component: () => import('@/views/backend/UGDTindakLanjut/List.vue'),
    meta: {
      menu: 'UGDTindakLanjut',
      pageTitle: 'Tindak Lanjut',
      objName: 'Tindak Lanjut',
    },
  },


  {
    path: 'ugd-form-lab/:pageSlug?/:rmNo?',
    name: 'UGDFormLab',
    component: () => import('@/views/backend/UGDFormLab/Form.vue'),
    meta: {
      menu: 'UGDFormLab',
      pageTitle: 'Form Assesmen Lab',
      objName: 'Form Assesmen Lab',
    },
  },
  {
    path: 'ugd-form-radiologi/:pageSlug?/:rmNo?',
    name: 'UGDFormRadiologi',
    component: () => import('@/views/backend/UGDFormRadiologi/Form.vue'),
    meta: {
      menu: 'UGDFormRadiologi',
      pageTitle: 'Form Assesmen Radiologi',
      objName: 'Form Assesmen Radiologi',
    },
  },
  
  {
    path: 'assesment-ugd-lab',
    name: 'UGDAssesmenLab',
    component: () => import('@/views/backend/UGDAssesmenLab/List.vue'),
    meta: {
      menu: 'UGDAssesmenLab',
      pageTitle: 'Assesment UGD Lab',
      objName: 'Assesment UGD Lab'
    },
  },

  {
    path: 'assesment-ugd-radiologi',
    name: 'UGDAssesmenRadiologi',
    component: () => import('@/views/backend/UGDAssesmenRadiologi/List.vue'),
    meta: {
      menu: 'UGDAssesmenRadiologi',
      pageTitle: 'Assesment UGD Radiologi',
      objName: 'Assesment UGD Radiologi'
    },
  },

  {
    path: 'ugd-form-lab/:pageSlug?/:rmNo?',
    name: 'UGDFormLab',
    component: () => import('@/views/backend/UGDFormLab/Form.vue'),
    meta: {
      menu: 'UGDFormLab',
      pageTitle: 'Form Assesmen Lab',
      objName: 'Form Assesmen Lab',
    },
  },

  {
    path: 'ugd-form-radiologi/:pageSlug?/:rmNo?',
    name: 'UGDFormRadiologi',
    component: () => import('@/views/backend/UGDFormRadiologi/Form.vue'),
    meta: {
      menu: 'UGDFormRadiologi',
      pageTitle: 'Form Assesmen Radiologi',
      objName: 'Form Assesmen Radiologi',
    },
  },

  {
    path: 'ugd-catatan-asuhan/:pageSlug',
    name: 'UGDCatatanAsuhan',
    component: () => import('@/views/backend/UGDCatatanAsuhan/Form.vue'),
    meta: {
      menu: 'UGDCatatanAsuhan',
      pageTitle: 'Form UGD Catatan Asuhan',
      objName: 'Form UGD Catatan Asuhan',
    },
  },
  {
    path: 'ugd-admisi-antrean',
    name: 'UGDAdmisiAntrean',
    component: () => import('@/views/backend/UGDAdmisiAntrean/List.vue'),
    meta: {
      menu: 'UGDAdmisiAntrean',
      pageTitle: 'UGD Admisi Antrean',
      objName: 'UGD Admisi Antrean',
    },
  },
  {
    path: 'display-antrian-ugd',
    name: 'DisplayAntrianUGD',
    component: () => import('@/views/display/DisplayAntrianUGD.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'display-antrian-admisi-ugd',
    name: 'DisplayAntrianAdmisiUGD',
    component: () => import('@/views/display/DisplayAntrianAdmisiUGD.vue'),
    meta: {
      layout: 'empty',
      isDisplay: true
    }
  },
  {
    path: 'ugd-farmasi/:pageSlug?',
    name: 'UGDFarmasi',
    component: () => import('@/views/backend/UGDFarmasi/List.vue'),
    meta: {
      menu: 'UGDFarmasi',
      pageTitle: 'UGD Farmasi',
      objName: 'UGD Farmasi',
    },
  },
  {
    path: 'rekonsiliasi-obat/:pageSlug?',
    name: 'UGDRekonsiliasi',
    component: () => import('@/views/backend/UGDRekonsiliasi/List.vue'),
    meta: {
      menu: 'UGDRekonsiliasi',
      pageTitle: 'UGD Rekonsiliasi',
      objName: 'UGD Rekonsiliasi',
    },
  },
  {
    path: 'ugd-rekam-medis/:pageSlug?',
    name: 'UGDRekamMedis',
    component: () => import('@/views/backend/UGDRekamMedis/List.vue'),
    meta: {
      menu: 'UGDRekamMedis',
      pageTitle: 'UGD Rekam Medis',
      objName: 'UGD Rekam Medis',
    },
  }, 
  {
    path: 'ugd-upla/:pageSlug?',
    name: 'UGDUPLA',
    component: () => import('@/views/backend/UGDUPLA/List.vue'),
    meta: {
      menu: 'UGDUPLA',
      pageTitle: 'UGD UPLA',
      objName: 'UGD UPLA',
    },
  },
  {
    path: 'ugd-rujukan-masuk/:pageSlug?',
    name: 'UGDRujukanMasuk',
    component: () => import('@/views/backend/UGDRujukanMasuk/List.vue'),
    meta: {
      menu: 'UGDRujukanMasuk',
      pageTitle: 'UGD Rujukan Masuk',
      objName: 'UGD Rujukan Masuk',
    },
  },
  {
    path: 'ugd-rujukan-keluar/:pageSlug?',
    name: 'UGDRujukanKeluar',
    component: () => import('@/views/backend/UGDRujukanKeluar/List.vue'),
    meta: {
      menu: 'UGDRujukanKeluar',
      pageTitle: 'UGD Rujukan Keluar',
      objName: 'UGD Rujukan Keluar',
    },
  },
  {
    path: 'ugd-penerbitan-surat/:pageSlug?',
    name: 'UGDPenerbitanSurat',
    component: () => import('@/views/backend/UGDPenerbitanSurat/List.vue'),
    meta: {
      menu: 'UGDPenerbitanSurat',
      pageTitle: 'UGD Penerbitan Surat',
      objName: 'UGD Penerbitan Surat',
    },
  },
  {
    path: 'ugd-penerbitan-surat/:pageSlug?',
    name: 'UGDPenerbitanSurat',
    component: () => import('@/views/backend/UGDPenerbitanSurat/List.vue'),
    meta: {
      menu: 'UGDPenerbitanSurat',
      pageTitle: 'UGD Penerbitan Surat',
      objName: 'UGD Penerbitan Surat',
    },
  },
  {
    path: 'ugd-surat-emergency/:pageSlug?',
    name: 'UGDSuratEmergency',
    component: () => import('@/views/backend/UGDSuratEmergency/List.vue'),
    meta: {
      menu: 'UGDSuratEmergency',
      pageTitle: 'UGD Surat Emergency',
      objName: 'UGD Surat Emergency',
    },
  },

  {
    path: 'ugd-report-admisi',
    name: 'UGDReportAdmisi',
    component: () => import('@/views/backend/UGDReportAdmisi/List.vue'),
    meta: {
      menu: 'UGDReportAdmisi',
      pageTitle: 'UGD Report Admisi',
      objName: 'UGD Report Admisi'
    },
  },
  
  {
    path: 'ugd-hais/:pageSlug?',
    name: 'UGDHais',
    component: () => import('@/views/backend/UGDHais/List.vue'),
    meta: {
      menu: 'UGDHais',
      pageTitle: 'UGD Hais',
      objName: 'UGD Hais'
    },
  },
  
  {
    path: 'ugd-pasien-ranap/:pageSlug?',
    name: 'UGDPasienRanap',
    component: () => import('@/views/backend/UGDPasienRanap/List.vue'),
    meta: {
      menu: 'UGDPasienRanap',
      pageTitle: 'Pasien Rawat Inap',
      objName: 'Pasien Rawat Inap'
    },
  },


  
  // UGD
]
export default bo_routes