<template>
  <div class="page-header">
    <div class="page-header-content header-elements-md-inline">
      <div class="page-title d-flex">
        <h4>
          <slot>
            <span class="font-weight-semibold">
              <template v-if="$route.params.project">
                RSH Harapan
              </template>
              <template v-else>
                {{ pageTitle || defPageTitle }}
              </template>
            </span>
          </slot>
        </h4>
        <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
      </div>
      <div class="header-elements d-none text-center text-md-left mb-3 mb-md-0">
        <div class="btn-group">
          <b-button class="bg-success-400 btn-labeled btn-labeled-left" :to="{ name: routeName }" v-if="(hasCta && !isList ) || (!isList && ctaList)" variant="transparent">
            <b><i class="icon-list"></i></b>Daftar {{ objName || pageTitle || defPageTitle }}
          </b-button>
          <b-button class="bg-blue-400 btn-labeled btn-labeled-left"
            v-else-if="hasCta && $parent.moduleRole('add')"
           :to="{ name: routeName, params: { pageSlug: 'add' } }" variant="transparent">
            <b><i class="icon-plus-circle2"></i></b> Tambah {{ objName || pageTitle || defPageTitle }}
          </b-button>
          <slot name="additionalCta" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
const _ = require('lodash')

export default {
  extends: GlobalVue,
  computed: {
    defPageTitle() { return _.startCase(this.$route.name) },
    pageTitle() { return this.$route.meta.pageTitle },
    breadcrumbs() { return this.$route.meta.breadcrumbs },
    objName() { return this.$route.meta.objName },
    hasCta() { return this.$route.meta.hasCta },
    ctaList() { return this.$route.meta.ctaList },
    hasBreadCrumb() { return this.$route.meta.hasBreadCrumb },
    isList() { return !this.$route.params.pageSlug },
    isAdd() { return this.$route.params.pageSlug == 'add' },
    routeName() { return this.$route.name },
    crumbs() {
      let path = this.$route.path.split('/')
      let mapped = path.filter((el, k) => k > 1).map(el => {
        return el >>> 0 === parseFloat(el) ? 'Edit' : _.startCase(el)
      })
      return mapped
    }
  }
}
</script>