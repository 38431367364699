const Config = {
  app:{
    webaname:'RSH Hospital',
    company:'RSH Hospital',
    copyright:()=>{
      let date = new Date()
      if(date.getFullYear()>2022){
        return '2022-'+date.getFullYear()
      }else{
        return '2022'
      }
    }
  },

  encrypt_key: "#@PI2022!!",
  datePickerOnlyDate: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
  mr:{
    StatusOptions: [
      { text: 'Aktif', value: 'Y' },
      { text: 'Nonaktif', value: 'N' },
    ],
    StatusMenular: [
      { text: 'Menular', value: 'Y' },
      { text: 'Tidak Menular', value: 'N' },
    ],    
    StatusPanggil: [
      { text: 'Hadir', value: 'Y' },
      { text: 'Belum Hadir', value: 'B' },
      { text: 'Tidak Hadir', value: 'N' },
    ],
    
    StatusPanggil2: [
      { text: 'Hadir', value: 'Y' },
      { text: 'Tidak Hadir', value: 'N' },
    ],
    StatusRegular: [
      { text: 'Regular', value: 'R' },
      { text: 'Iregular', value: 'I' },
    ],
    StatusPernikahan: [
      { text: 'Belum Kawin', value: 1 },
      { text: 'Kawin', value: 2 },
      { text: 'Cerai Hidup', value: 3 },
      { text: 'Cerai Mati', value: 4 },
    ],
    StatusKelamin: [
      {
        text: 'LAKI-LAKI',
        value: 1
      }, {
        text: 'PEREMPUAN',
        value: 2
      }, {
        text: 'TIDAK DIKETAHUI',
        value: "0"
      }, {
        text: 'TIDAK DAPAT DITENTUKAN',
        value: 3
      }, {
        text: 'TIDAK MENGISI',
        value: 4
      },
    ],
    
    
    KategoriRadiologi: [{
        text: 'Radiologi Tanpa Kontras',
        value: 1
      },
      {
        text: 'Ultrasonografi (USG)',
        value: 2
      },
      {
        text: 'Radiologi dengan Kontras',
        value: 3
      },
      {
        text: 'Pemeriksaan CT-SCAN',
        value: 4
      }
    ],
    KategoriLab: [{
        text: 'Hematologi',
        value: 'Hematologi'
      },
      {
        text: 'Kimia',
        value: 'Kimia'
      },
      {
        text: 'Imuno Serologi',
        value: 'Imuno Serologi'
      },
      {
        text: 'Urinalisis',
        value: 'Urinalisis'
      },
      {
        text: 'Analisa Faeces',
        value: 'Analisa Faeces'
      },
      {
        text: 'Endokrinologi',
        value: 'Endokrinologi'
      },
      {
        text: 'Osteoporosis',
        value: 'Osteoporosis'
      },
      {
        text: 'Lain-lain',
        value: 'Lain-lain'
      },
    ],
    gender: [
      { text: 'Laki-Laki', value: 'P' },
      { text: 'Perempuan', value: 'W' },
    ],
    yesNoOpt: [
      { text: 'Ya', value: 'Y' },
      { text: 'Tidak Ada', value: 'N' },
    ],
    yesNoOptV2: [
      { text: 'Ya', value: 'Y' },
      { text: 'Tidak', value: 'N' },
    ],
    yesNoOptV3: [
      { text: 'Ya', value: 1 },
      { text: 'Tidak', value: 0 },
    ],
    golDarah: [
      { text: 'A', value: 'A' },
      { text: 'B', value: 'B' },
      { text: 'O', value: 'O' },
      { text: 'AB', value: 'AB' },
      { text: 'Tidak Tahu', value: 'Tidak Tahu' }
    ],
    baikNoOpt: [
      { text: 'Baik', value: 'B' },
      { text: 'Tidak Baik', value: 'TB' },
    ],
    psikologiPot: [
      { 
        text: 'Tidak ada kelainan', 
        value: 1 
      },
      { 
        text: 'Cemas', 
        value: 2 
      },
      {
        text: 'Takut',
        value: 3
      },
      {
        text: 'Marah',
        value: 4
      },
      {
        text: 'Sedih',
        value: 5
      },
      {
        text: 'Lain-lain',
        value: 6
      },
    ],
    adlStatus: [
      {
        text: 'Mandiri',
        value: 'MANDIRI'
      },
      {
        text: 'Dibantu',
        value: 'DIBANTU'
      },
    ],
    skalaNyeri: [
      {
        text: '0',
        value: 0,
        class: 'psr-blue',
      },
      {
        text: '1',
        value: 1,
        class: 'psr-green',
      },
      {
        text: '2',
        value: 2,
        class: 'psr-green',
      },
      {
        text: '3',
        value: 3,
        class: 'psr-light-green',
      },
      {
        text: '4',
        value: 4,
        class: 'psr-light-green',
      },
      {
        text: '5',
        value: 5,
        class: 'psr-yellow',
      },
      {
        text: '6',
        value: 6,
        class: 'psr-yellow',
      },
      {
        text: '7',
        value: 7,
        class: 'psr-orange',
      },
      {
        text: '8',
        value: 8,
        class: 'psr-orange',
      },
      {
        text: '9',
        value: 9,
        class: 'psr-red',
      },
      {
        text: '10',
        value: 10,
        class: 'psr-red',
      },
    ],
    diagnosaKhusus: [
      {
        text: 'Tidak Ada',
        value: 'Tidak Ada'
      },
      {
        text: 'Diabetes Mellitus',
        value: 'Diabetes Mellitus'
      },
      {
        text: 'Hipertensi',
        value: 'Hipertensi'
      },
      {
        text: 'Cronical Renal Failure',
        value: 'Cronical Renal Failure'
      },
      {
        text: 'Infeksi Kronis',
        value: 'Infeksi Kronis'
      },
      {
        text: 'Malnutrisi',
        value: 'Malnutrisi'
      },
      {
        text: 'Obesitas',
        value: 'Obesitas'
      },
      {
        text: 'Lschemic Heart Disease',
        value: 'Lschemic Heart Disease'
      },
      {
        text: 'Cences',
        value: 'Cences'
      },
      {
        text: 'Lainnya',
        value: 'L'
      },
    ],
    kriteriaHasil: [
      {
        text: '1',
        value: 1
      },
      {
        text: '2',
        value: 2
      },
      {
        text: '3',
        value: 3
      },
      {
        text: '4',
        value: 4
      },
      {
        text: '5',
        value: 5
      }
    ],
    configStatus: [
      {
        text: 'SEMUA STATUS',
        value: 'ALL'
      },
      {
        text: 'DALAM ANTRIAN',
        value: 'QUEUE'
      },
      {
        text: 'TIDAK HADIR',
        value: 'ABSEN'
      },
      {
        text: 'DALAM TINDAKAN',
        value: 'ACTION'
      },
      {
        text: 'VOID',
        value: 'VOID'
      },
      {
        text: 'SELESAI',
        value: 'DONE'
      }
    ],
    configStatusTidakHadir: [{
        text: 'SEMUA STATUS',
        value: 'ALL'
      },
      {
        text: 'TIDAK HADIR',
        value: 'ABSEN'
      },
      {
        text: 'VOID',
        value: 'VOID'
      }
    ],
    configStatusFarmasi: [
      {
        text: 'ALL',
        value: 'ALL'
      },
      {
        text: 'PENDING',
        value: 'N'
      },
      {
        text: 'SELESAI',
        value: 'Y'
      }
    ],
    configStatusBilling: [{
        text: 'ALL',
        value: 'ALL'
      },
      {
        text: 'PENDING',
        value: 'N'
      },
      {
        text: 'SELESAI',
        value: 'Y'
      }
    ],
    configStatusKasir: [{
        text: 'ALL',
        value: 'ALL'
      },
      {
        text: 'PENDING',
        value: 'N'
      },
      {
        text: 'SELESAI',
        value: 'Y'
      }
    ],
    configStatusRadiologiLab: [{
        text: 'ALL',
        value: 'ALL'
      },
      {
        text: 'DALAM TINDAKAN',
        value: 'N'
      },
      {
        text: 'SELESAI TINDAKAN',
        value: 'Y'
      }
    ],
    mCaraPulang: [{
        text: 'Pulang',
        value: 'P'
      },
      {
        text: 'Kontrol',
        value: 'K'
      },
      {
        text: 'Rujuk',
        value: 'RU'
      },
      {
        text: 'Rawat Inap',
        value: 'RI'
      }
    ],
    configStatusLokalis: [
      {
        text: 'Anatomi Badan',
        value: 'body',
        image: 'img/anatomy-male.png',
        image2: 'img/anatomy-female.png',
      },
      {
        text: 'Anatomi Mata',
        value: 'eye',
        image: 'img/anatomy-collection/eye-anatomy.png',
        image2: 'img/anatomy-collection/eye-anatomy.png',
      },
      {
        text: 'Anatomi Mata Depan',
        value: 'front-eye',
        image: 'img/anatomy-collection/front-eye-anatomy.png',
        image2: 'img/anatomy-collection/front-eye-anatomy.png',
      },
      {
        text: 'Odontogram',
        value: 'odontogram',
        image: 'img/anatomy-collection/odontogram.png',
        image2: 'img/anatomy-collection/odontogram.png',
      },
      {
        text: 'Anatomi Syaraf',
        value: 'nervousSystem',
        image: 'img/anatomy-collection/nervous-anatomy.png',
        image2: 'img/anatomy-collection/nervous-anatomy.png',
      },
      {
        text: 'Anatomi Jantung',
        value: 'heart',
        image: 'img/anatomy-collection/heart-anatomy.png',
        image2: 'img/anatomy-collection/heart-anatomy.png',
      },
      {
        text: 'Anatomi Telinga',
        value: 'ear',
        image: 'img/anatomy-collection/ear-anatomy.png',
        image2: 'img/anatomy-collection/ear-anatomy.png',
      },
      {
        text: 'Anatomi Hidung',
        value: 'nose',
        image: 'img/anatomy-collection/nose-anatomy.png',
        image2: 'img/anatomy-collection/nose-anatomy.png',
      },
      {
        text: 'Anatomi Tenggorokan',
        value: 'throat',
        image: 'img/anatomy-collection/pharynx-anatomy.png',
        image2: 'img/anatomy-collection/pharynx-anatomy.png',
      },
      {
        text: 'Anatomi Paru',
        value: 'lung',
        image: 'img/anatomy-collection/lung-anatomy.png',
        image2: 'img/anatomy-collection/lung-anatomy.png',
      },
      {
        text: 'Anatomi Sendi',
        value: 'joint',
        image: 'img/anatomy-collection/joint-anatomy.png',
        image2: 'img/anatomy-collection/joint-anatomy.png',
      },
    ],
    isMovePoli: [
      {
        text: 'Ya, Pindah Poli',
        value: 'Y'
      },
      {
        text: 'Tidak',
        value: 'N'
      },
    ],
    mKesadaran: [
      {
        text: 'Composmentris (GCS 14 - 15)',
        value: 1,
      },
      {
        text: 'Apatis (GCS 12 - 13)',
        value: 2,
      },
      {
        text: 'Somenolen (GCS 10 - 11)',
        value: 3,
      },
      {
        text: 'Delirium (GCS 7 - 9)',
        value: 4,
      },
      {
        text: 'Soporo (GCS 4 - 6)',
        value: 5,
      },
      {
        text: 'Coma (GCS 1 - 3)',
        value: 6,
      },
    ],
    keselamatanPasienA: [{
        text: 'Tidak seimbang/sempoyongan/limbung',
        value: 'Tidak seimbang/sempoyongan/limbung'
      },
      {
        text: 'Jalan dengan menggunakan alat bantu (kruk, tripot, kursi roda, orang lain)',
        value: 'Jalan dengan menggunakan alat bantu (kruk, tripot, kursi roda, orang lain)'
      },
    ],
    keselamatanPasienB: [{
        text: 'Menopang saat akan duduk : tampak memegang pinggiran kursi atau meja ata benda lain sebagai penopang saat akan duduk',
        value: 'Menopang saat akan duduk : tampak memegang pinggiran kursi atau meja ata benda lain sebagai penopang saat akan duduk'
      }
    ],

    jenisInsiden: [
      {
        text: 'KNC',
        value: 1
      },
      {
        text: 'KTD',
        value: 2
      },
      {
        text: 'KTC',
        value: 3
      },
      {
        text: 'KPC',
        value: 4
      },
      {
        text: 'Sentinel',
        value: 5
      },
    ],
    mReportInsiden: [{
        text: 'Karyawan',
        value: 'Karyawan'
      },
      {
        text: 'Pasien',
        value: 'Pasien'
      },
      {
        text: 'Keluarga / Pendamping pasien',
        value: 'Keluarga / Pendamping pasien'
      },
      {
        text: 'Pengunjung',
        value: 'Pengunjung'
      },
      {
        text: 'Lain-Lain',
        value: 'L'
      },
    ],
    mOccure: [{
        text: 'Pasien',
        value: 'Pasien'
      },
      {
        text: 'Lain-Lain',
        value: 'L'
      },
    ],
    insidenPasien: [
      {
        text: 'Pasien Rawat Inap',
        value: 'Pasien Rawat Inap'
      },
      {
        text: 'Pasien Rawat Jalan',
        value: 'Pasien Rawat Jalan'
      },
      {
        text: 'Pasien UGD',
        value: 'Pasien UGD'
      },
      {
        text: 'Lain-lain',
        value: 'L'
      },
    ],
    akibatInsidenPadaPasien: [
      {
        text: 'Kematian',
        value: 'Kematian'
      }, {
        text: 'Cedera Irreversibel / Cedera Berat',
        value: 'Cedera Irreversibel / Cedera Berat'
      }, {
        text: 'Cedera Reversibel / Cedera Sedang',
        value: 'Cedera Reversibel / Cedera Sedang'
      }, {
        text: 'Cedera Ringan',
        value: 'Cedera Ringan'
      }, {
        text: 'Tidak ada cedera',
        value: 'Tidak ada cedera'
      }
    ],

    tindakanOleh: [{
      text: 'Tim',
      value: 'Tim'
    }, {
      text: 'Dokter',
      value: 'Dokter'
    }, {
      text: 'Perawat',
      value: 'Perawat'
    }, {
      text: 'Petugas Lainnya',
      value: 'Petugas Lainnya'
    }],

    probabilitasOpt: [{
      text: 'Sangat Jarang (>5 tahun/kali)',
      value: 'Sangat Jarang (>5 tahun/kali)'
    }, {
      text: 'Jarang (>2-5 tahun/kali)',
      value: 'Jarang (>2-5 tahun/kali)'
    }, {
      text: 'Mungkin (1-2 tahun/ kali)',
      value: 'Mungkin (1-2 tahun/ kali)'
    }, {
      text: 'Sering (beberapa kali/tahun)',
      value: 'Sering (beberapa kali/tahun)'
    }, {
      text: 'Sangat sering (tiap minggu/ bulan)',
      value: 'Sangat sering (tiap minggu/ bulan)'
    }
   ],

   gradingOpt: [{
     text: 'BIRU',
     value: 'BIRU'
   }, {
     text: 'HIJAU',
     value: 'HIJAU'
   }, {
     text: 'KUNING',
     value: 'KUNING'
   }, {
     text: 'MERAH',
     value: 'MERAH'
   }],

  configResep: [
    {
      text: 'Obat',
      value: 1
    },
    {
      text: 'Alat Kesehatan',
      value: 2
    },
    {
      text: 'Racikan',
      value: 3
    },
  ],

  configStatusRM: [
    {
      text: 'Semua Status',
      value: 'ALL'
    },{
      text: 'Verifikasi Rekam Medis',
      value: 6
    },{
      text: 'Verifikasi Upla',
      value: 8
    }, {
      text: 'Selesai',
      value: 7
    },
  ],
 
  configStatusUpla: [{
    text: 'Semua Status',
    value: 'ALL'
  }, {
    text: 'Verifikasi Upla',
    value: 8
  }, {
    text: 'Selesai',
    value: 7
  }, ],


   configStatusRMUGD: [
    {
      text: 'Semua Status',
      value: 'ALL'
    },{
      text: 'Verifikasi Rekam Medis',
      value: 4
    },{
      text: 'Verifikasi Upla',
      value: 5
    }, {
      text: 'Selesai',
      value: 6
    },
  ],
 
  configStatusUplaUGD: [{
    text: 'Semua Status',
    value: 'ALL'
  }, {
    text: 'Verifikasi Upla',
    value: 5
  }, {
    text: 'Selesai',
    value: 6
  } 
  ],


  configTindakLanjut: [
    {
      text: 'Edukasi',
      value: 'Edukasi'
    },
    {
      text: 'Kontrol',
      value: 'Kontrol'
    },
    {
      text: 'Rawat Inap',
      value: 'Rawat Inap'
    },
  ],

  configPrioritasPemeriksaan: [
    {
      text: 'CITO',
      value: 1
    },
    {
      text: 'Non-CITO',
      value: 2
    },
  ],
  
  configAnamnesa: [
    {
      text: 'Pasien Sendiri',
      value: 1
    },
    {
      text: 'Orang Lain',
      value: 2
    },
  ],
  
  configNilaiNyeri: [
    {
      text: 'Tidak',
      value: 1
    },
    {
      text: 'Ya',
      value: 2
    },
    {
      text: 'Sulit Dinilai',
      value: 3
    },
  ],

  configLamaNyeri: [
    {
      text: '> 3 bulan(Kronik)',
      value: 1
    },
    {
      text: '< 3 bulan (Akut)',
      value: 2
    },
    {
      text: 'Sulit Dinilai',
      value: 3
    },
  ],

  
  statusGizi: [
    {
      text: 'Gizi Kurang/Buruk',
      value: 'Gizi Kurang/Buruk'
    },
    {
      text: 'Gizi Cukup',
      value: 'Gizi Cukup'
    },
    {
      text: 'Gizi Lebih',
      value: 'Gizi Lebih'
    },
    {
      text: 'Lainnya',
      value: 'L'
    },
  ],
  
  filterTypeLaporan: [
    {
      text: 'SEMUA KATEGORI',
      value: 'ALL'
    },
    {
      text: 'REKAM MEDIS',
      value: 'RM'
    },
    {
      text: 'SPLPA',
      value: 'SPLPA'
    },
    {
      text: 'LAINNYA',
      value: 'L'
    },
  ],

  statusNyeri: [
    {
      text: 'Tidak ada Nyeri',
      value: 'Tidak ada Nyeri'
    },
    {
      text: 'Tajam',
      value: 'Tajam'
    },
    {
      text: 'Seperti ditarik',
      value: 'Seperti ditarik'
    },
    {
      text: 'Seperti ditusuk',
      value: 'Seperti ditusuk'
    },
    {
      text: 'Seperti dibakar',
      value: 'Seperti dibakar'
    },
    {
      text: 'Seperti ditikam',
      value: 'Seperti ditikam'
    },
    {
      text: 'Nyeri tumpul',
      value: 'Nyeri tumpul'
    },
    {
      text: 'Seperti dipukul',
      value: 'Seperti dipukul'
    },
    {
      text: 'Seperti berdenyut',
      value: 'Seperti berdenyut'
    },
    {
      text: 'Seperti kram',
      value: 'Seperti kram'
    },
    {
      text: 'Sulit Dinilai',
      value: 'Sulit Dinilai'
    },
  ],

  kompres:[
    {
      text: 'Hangat',
      value: 'Hangat'
    },
    {
      text: 'Dingin',
      value: 'Dingin'
    },
  ],
  
  aktifitas:[
    {
      text: 'Dikurangi',
      value: 'Dikurangi'
    },
    {
      text: 'Ditambah',
      value: 'Ditambah'
    },
  ],
  
  statusDirujuk: [
    {
      text: 'Klinik Gizi',
      value: 'Klinik Gizi'
    },
    {
      text: 'Tim Nyeri',
      value: 'Tim Nyeri'
    },
    {
      text: 'Edukasi',
      value: 'Edukasi'
    },
    {
      text: 'Lainnya',
      value: 'L'
    },
  ],
  
  statusElektroterapi: [
    {
      text: 'Short Wave Diaterm / UKG',
      value: 'Short Wave Diaterm / UKG'
    },
    {
      text: 'Stimulasi (Faradik/ Galvanik)',
      value: 'Stimulasi (Faradik/ Galvanik)'
    },
    {
      text: 'Ultra Sonik',
      value: 'Ultra Sonik'
    }
  ],
  
  statusMekanoTerapi: [
    {
      text: 'Traksi Cervical / Lumbal',
      value: 'Traksi Cervical / Lumbal'
    },
    {
      text: 'Massage',
      value: 'Massage'
    },
    {
      text: 'Exercise Terapi',
      value: 'Exercise Terapi'
    }
  ],

  statusLatihan: [
    {
      text: 'Penguatan Otot',
      value: 'Penguatan Otot'
    },
    {
      text: 'Koordinasi Gerak',
      value: 'Koordinasi Gerak'
    },
    {
      text: 'Breathing Exersice',
      value: 'Breathing Exersice'
    },
    {
      text: 'Pergerakan Sendi',
      value: 'Pergerakan Sendi'
    },
    {
      text: 'Latihan Jalan',
      value: 'Latihan Jalan'
    },
    {
      text: 'Latihan pra dan pasca bedah',
      value: 'Latihan pra dan pasca bedah'
    },
  ],
  kategoriAsupan: [
    {
      text: 'Kurang',
      value: 'Kurang'
    },
    {
      text: 'Baik',
      value: 'Baik'
    },
    {
      text: 'Lebih',
      value: 'Lebih'
    }
  ],
  kategoriTindakan: [{
      text: 'Elektroterapi',
      value: 'E'
    },
    {
      text: 'Mekanoterapi',
      value: 'M'
    },
    {
      text: 'Latihan-latihan',
      value: 'L'
    }
  ],

  rencanaTindakan: [{
      text: 'Diathermi',
      value: 'Diathermi'
    },
    {
      text: 'Stimulasi (Tens)',
      value: 'Stimulasi (Tens)'
    },
    {
      text: 'Traksi Lumbal (TL)',
      value: 'Traksi Lumbal (TL)'
    },
    {
      text: 'UltraSound',
      value: 'UltraSound'
    },
    {
      text: 'Infra Red (IR)',
      value: 'Infra Red (IR)'
    },
    {
      text: 'Traksi Cervical (TC)',
      value: 'Traksi Cervical (TC)'
    },
    {
      text: 'Exercise',
      value: 'Exercise'
    },
  ],
  typeSatuan: [{
      text: 'BUNGKUS',
      value: 'BUNGKUS'
    },
    {
      text: 'KAPSUL',
      value: 'KAPSUL'
    },
    {
      text: 'BOTOL',
      value: 'BOTOL'
    },
    {
      text: 'TUBE',
      value: 'TUBE'
    },
    {
      text: 'POT',
      value: 'POT'
    },
  ],
  rentangUmur: [{
      text: 'SEMUA RENTANG UMUR',
      value: 'ALL'
    },
    {
      text: 'BAYI',
      value: 'BAYI'
    },
    {
      text: 'ANAK-ANAK',
      value: 'ANAK-ANAK'
    },
    {
      text: 'DEWASA',
      value: 'DEWASA'
    },
    {
      text: 'LANSIA',
      value: 'LANSIA'
    },
  ],
  jenisKelamin: [{
      text: 'SEMUA JENIS KELAMIN',
      value: 'ALL'
    },
    {
      text: 'LAKI-LAKI',
      value: 1
    },
    {
      text: 'PEREMPUAN',
      value: 2
    },
    {
      text: 'TIDAK DIKETAHUI',
      value: 0
    },
    {
      text: 'TIDAK DAPAT DITENTUKAN',
      value: 3
    },
    {
      text: 'TIDAK MENGISI',
      value: 4
    },
  ],
  statusPasien: [{
      text: 'SEMUA STATUS',
      value: 'ALL'
    },
    {
      text: 'PASIEN LAMA',
      value: 'Y'
    },
    {
      text: 'PASIEN BARU',
      value: 'N'
    },
  ],
  isPerawatOrDokter: [
    {
      text: 'Perawat',
      value: 'Perawat'
    },{
      text: 'Dokter',
      value: 'Dokter'
    }
  ],
  configVitalSign: [{
      'value': 'apod_vs_kepala',
      'label': 'Kepala',
      'isOpen': false
    },
    {
      'value': 'apod_vs_mata',
      'label': 'Mata',
      'isOpen': false
    },
    {
      'value': 'apod_vs_hidung',
      'label': 'Hidung',
      'isOpen': false
    },
    {
      'value': 'apod_vs_rambut',
      'label': 'Rambut',
      'isOpen': false
    },
    {
      'value': 'apod_vs_bibir',
      'label': 'Bibir',
      'isOpen': false
    },
    {
      'value': 'apod_vs_gigi',
      'label': 'Gigi',
      'isOpen': false
    },
    {
      'value': 'apod_vs_lidah',
      'label': 'Lidah',
      'isOpen': false
    },
    {
      'value': 'apod_vs_langit',
      'label': 'Langit',
      'isOpen': false
    },
    {
      'value': 'apod_vs_leher',
      'label': 'Leher',
      'isOpen': false
    },
    {
      'value': 'apod_vs_tenggorokan',
      'label': 'Tenggorokan',
      'isOpen': false
    },
    {
      'value': 'apod_vs_tonsil',
      'label': 'Tonsil',
      'isOpen': false
    },
    {
      'value': 'apod_vs_dada',
      'label': 'Dada',
      'isOpen': false
    },
    {
      'value': 'apod_vs_payudara',
      'label': 'Payudara',
      'isOpen': false
    },
    {
      'value': 'apod_vs_punggung',
      'label': 'Punggung',
      'isOpen': false
    },
    {
      'value': 'apod_vs_perut',
      'label': 'Perut',
      'isOpen': false
    },
    {
      'value': 'apod_vs_genital',
      'label': 'Genital',
      'isOpen': false
    },
    {
      'value': 'apod_vs_anus',
      'label': 'Anus',
      'isOpen': false
    },
    {
      'value': 'apod_vs_lengan_atas',
      'label': 'Lengan Atas',
      'isOpen': false
    },
    {
      'value': 'apod_vs_lengan_bawah',
      'label': 'Lengan Bawah',
      'isOpen': false
    },
    {
      'value': 'apod_vs_jari_tangan',
      'label': 'Jari Tangan',
      'isOpen': false
    },
    {
      'value': 'apod_vs_kuku',
      'label': 'Kuku',
      'isOpen': false
    },
    {
      'value': 'apod_vs_sendi_tangan',
      'label': 'Sendi Tangan',
      'isOpen': false
    },
    {
      'value': 'apod_vs_tungkai_atas',
      'label': 'Tungkai Atas',
      'isOpen': false
    },
    {
      'value': 'apod_vs_tungkai_bawah',
      'label': 'Tungkai Bawah',
      'isOpen': false
    },
    {
      'value': 'apod_vs_jari_kaki',
      'label': 'Jari Kaki',
      'isOpen': false
    },
    {
      'value': 'apod_vs_kuku_kaki',
      'label': 'Kuku Kaki',
      'isOpen': false
    },
    {
      'value': 'apod_vs_persendian_kaki',
      'label': 'Persendian Kaki',
      'isOpen': false
    }
  ],
  jenisAlergi: [{
    text: 'Obat',
    value: 'Obat'
  },
  {
    text: 'Makanan',
    value: 'Makanan'
  },
  {
    text: 'Udara',
    value: 'Udara'
  },
  {
    text: 'Lainnya',
    value: 'Lainnya'
  },
  ],


  // baru
  statusKewarganegaraan: [{
      text: 'WNA',
      value: 'WNA'
    },
    {
      text: 'WNI',
      value: 'WNI'
    },
  ],
  statusIzin: [{
      text: 'Mengijinkan',
      value: 'Y'
    },
  ],
  statusPemeriksaan: [{
      text: 'Semua',
      value: 'ALL'
    },
    {
      text: 'Poliklinik',
      value: 'Poli'
    },
    {
      text: 'Penunjang',
      value: 'Penunjang'
    },
  ],
  statusRujukan: [
    {
      text: 'Menunggu',
      value: 'Q'
    },
    {
      text: 'Selesai',
      value: 'D'
    }
  ],
  tipeRujukan: [{
      text: 'Rujuk Internal',
      value: 'RI'
    },
    {
      text: 'Rujuk Kasus',
      value: 'RK'
    },
    {
      text: 'Rujuk Parsial',
      value: 'RP'
    },
    {
      text: 'Rujuk Balik',
      value: 'RB'
    }
  ],
  jenisRujukan : [
    { "text": "Puskesmas/Klinik/Dokter Keluarga", "value": 1},
    { "text": "Rumah Sakit", "value": 2}
  ],
  jenisDokumen : [
    { "text": 'Surat Rujukan', "value": "R"},
    { "text": 'Surat Kontrol', "value": "K"}
  ],
  statusJaminan: [{
      text: 'Semua Cara Bayar',
      value: 'ALL'
    },
    {
      text: 'Umum/Pribadi',
      value: 1
    },
    {
      text: 'Asuransi/Perusahaan',
      value: 2
    },
    {
      text: 'BPJS Kesehatan',
      value: 3
    },
  ],
  mJaminan: [
    {
      text: 'Umum/Pribadi',
      value: 1
    },
    {
      text: 'Asuransi/Perusahaan',
      value: 2
    },
    {
      text: 'BPJS Kesehatan',
      value: 3
    },
  ],
  statusPemeriksaanOpt: [
    {
      text: 'Poliklinik',
      value: 'Poli'
    },
    {
      text: 'Penunjang',
      value: 'Penunjang'
    },
  ],
  
  statusPemeriksaanOpt2: [
    {
      text: 'Poliklinik',
      value: 'Poli'
    },
  ],

  diagnosaRujukBalik: [
      {
        value: "01",
        text: "Diabetes Mellitus"
      },
      {
        value: "02",
        text: "Hypertensi"
      },
      {
        value: "03",
        text: "Asthma"
      },
      {
        value: "04",
        text: "Penyakit Jantung"
      },
      {
        value: "05",
        text: "PPOK (Penyakit Paru Obstruktif Kronik)"
      },
      {
        value: "06",
        text: "Schizophrenia"
      },
      {
        value: "07",
        text: "Stroke"
      },
      {
        value: "08",
        text: "Epilepsi"
      },
      {
        value: "09",
        text: "Systemic Lupus Erythematosus"
      }
  ],
  mWaktuPeriksa : [
     {
       "text": "Pagi (06:30 s/d 14:00)",
       "value": "06:30 s/d 14:00",
       "started": "06:30",
       "ended": "14:00"
     }, {
       "text": "Sore (14:00 s/d 17:00)",
       "value": "14:00 s/d 17:00",
       "started": "14:00",
       "ended": "17:00"
     }, {
       "text": "Malam (17:00 s/d 22:00)",
       "value": "17:00 s/d 22:00",
       "started": "17:00",
       "ended": "22:00"
     }
  ],

  statusPenunjangOpt: [{
      text: 'Laboratorium',
      value: 'LAB'
    },
    {
      text: 'Radiologi',
      value: 'RADIO'
    },
    {
      text: 'Fisioterapi',
      value: 'FISIO'
    },
    {
      text: 'Konsul Gizi',
      value: 'DIETISIEN'
    },
  ],
  mFastTrack: [
    {
      text: 'Menggunakan Alat Bantu',
      value: 'Y'
    },
    {
      text: 'Lainnya',
      value: 'N'
    },
  ],
  mTypeJaminan: [
    {text: "BPJS Kesehatan", value: "B"},
    {text: "Umum", value: "U"}, 
  ],
  mPelayanan: [{
      text: "Rawat Inap",
      value: "RI"
    },
    {
      text: "Rawat Jalan",
      value: "RJ"
    },
  ],
  mRujukan: [{
      text: "Penuh",
      value: "F"
    },
    {
      text: "Partial",
      value: "P"
    },
    {
      text: "Rujuk Balik(Non PRBI)",
      value: "B"
    },
  ],

  
  mRujukanUGD: [{
      text: "Penuh",
      value: "0"
    },
    {
      text: "Partial",
      value: "1"
    }
  ],
  
  mTypeSEP: [{
      text: "Terbit SEP Normal",
      value: "N"
    },
    {
      text: "Terbit SEP Kontrol",
      value: "K"
    },
    // {
    //   text: "Terbit SEP Rawat Inap",
    //   value: "R"
    //   // lumayan buat ranap
    // },
    // {
    //   text: "Terbit SEP UGD",
    //   value: "I"
    // },
    {
      text: "Terbit SEP POST OPNAME",
      value: "O"
    },
  ],

  
  mTypeSEPUGD: [
    {
      text: "Terbit SEP UGD",
      value: "I"
    }
  ],

   mVerifFarmasi: [{
       text: "Dalam Proses",
       value: "N"
     },
     {
       text: "Verifikasi BPJS",
       value: "B"
     },
     {
       text: "Verifikasi Asuransi",
       value: "A"
     }
   ],

  mUnitCategory: [
    {
      text: "Admisi",
      value: "admisi"
    },
    {
      text: "Loket Perawat",
      value: "loket"
    },
    {
      text: "Penunjang",
      value: "penunjang"
    },
    {
      text: "Poli",
      value: "poli"
    },
    {
      text: "Farmasi",
      value: "farmasi"
    },
    {
      text: "Admisi UGD",
      value: "admisi-ugd"
    },
    {
      text: "Farmasi UGD",
      value: "farmasi-ugd"
    },
  ],
  typeRujukan: [
    {
      text: "Rujuk Internal",
      value: "RI"
    }, {
      text: "Rujuk Kasus",
      value: "RK"
    }, {
      text: "Rujuk Parsial",
      value: "RP"
    }, {
      text: "Rujuk Balik (PRB)",
      value: "RB"
    }, {
      text: "Rujuk Balik (NON PRB)",
      value: "RBN"
    },
  ],
  typeRujukanV2: [
    {
      text: "Rujuk Internal",
      value: "RI"
    },{
      text: "Rujuk Kasus",
      value: "RK"
    }, {
      text: "Rujuk Parsial",
      value: "RP"
    },
  ],
  alasanRujuk: [{
      text: "Klinis",
      value: "Klinis"
    }, {
      text: "Non Klinis",
      value: "Non Klinis"
    }, {
      text: "Lain-lain",
      value: "Lain-lain"
    },
  ],
  mediaTerpasang: [
    {
      text: "Infus",
      value: "Infus"
    }, {
      text: "NGT",
      value: "NGT"
    }, {
      text: "DC",
      value: "DC"
    }, {
      text: "Oksigen",
      value: "Oksigen"
    },
  ],

  jenisFaskes: [{
    text: "Faskes",
    value: 1
  }, {
    text: "Faskes/RS",
    value: 2
  }],

  jenisPelayanan: [{
    text: "Rawat Inap",
    value: 1
  }, {
    text: "Rawat Jalan",
    value: 2
  }],

  statusKlaim: [{
    text: "Proses Verifikasi",
    value: 1
  }, {
    text: "Pending Verifikasi",
    value: 2
  }, {
    text: "Klaim",
    value: 3
  }],

  typeRujukanRS: [{
    text: "PCare",
    value: 1
  }, {
    text: "Rumah Sakit",
    value: 2
  }],

  jenisNOKA: [{
    text: "NIK",
    value: "nik"
  }, {
    text: "Nomor Kartu",
    value: "noka"
  }],

  jenisFormat: [{
    text: "Tanggal Entri",
    value: 1
  }, {
    text: "Tanggal R. Kontrol",
    value: 2
  }],

  jenisKontrol: [{
    text: "SPRI (Nomor Kartu)",
    value: 1
  }, {
    text: "Rencana Kontrol (Nomor SEP)",
    value: 2
  }],

  configLakaLantas: [
    {
      value: "0",
      text: "Bukan Kecelakaan lalu lintas [BKLL]"
    },
    {
      value: 1,
      text: "KLL dan bukan kecelakaan Kerja [BKK]"
    },
    {
      value: 2,
      text: "KLL dan KK"
    },
    {
      value: 3,
      text: "KK"
    },
  ],

  configTujuan: [{
      value: "0",
      text: "Normal"
    },
    {
      value: "1",
      text: "Prosedur"
    },
    {
      value: "2",
      text: "Konsul Dokter"
    }
  ],

  configLayanan: [
    {
      value: "1",
      text: "Poli spesialis tidak tersedia pada hari sebelumnya"
    },

    {
      value: "2",
      text: "Jam Poli telah berakhir pada hari sebelumnya"
    },

    {
      value: "3",
      text: "Dokter Spesialis yang dimaksud tidak praktek pada hari sebelumnya"
    },

    {
      value: "4",
      text: "Atas Instruksi RS"
    },

    {
      value: "5",
      text: "Tujuan Kontrol"
    },
  ],
  
  configPenunjang: [
    {
      value : 1,
      text: "Radioterapi"
    },
    
    {
      value : 2,
      text: "Kemoterapi"
    },
    
    {
      value : 3,
      text: "Rehabilitasi Medik"
    },
    
    {
      value : 4,
      text: "Rehabilitasi Psikososial"
    },
    
    {
      value : 5,
      text: "Transfusi Darah"
    },
    
    {
      value : 6,
      text: "Pelayanan Gigi"
    },
    
    {
      value : 7,
      text: "Laboratorium"
    },
    
    {
      value : 8,
      text: "USG"
    },
    
    {
      value : 9,
      text: "Farmasi"
    },
    
    {
      value : 10,
      text: "Lain - Lain"
    },
    
    {
      value : 11,
      text: "MRI"
    },
    
    {
      value : 12,
      text: "HEMODIALISA"
    },
  ],
  configFlag: [
    {
      value: 0,
      text: "Prosedur Tidak Berkelanjutan"
    },

    {
      value: 1,
      text: "Prosedur dan Terapi Berkelanjutan"
    },
  ],
  kelasRawat: [{
      value: 1,
      text: "Kelas 1"
    },
    {
      value: 2,
      text: "Kelas 2"
    },
    {
      value: 3,
      text: "Kelas 3"
    },
  ],


  configStatusKondisi: [{
      text: 'Baik',
      value: 'Baik'
    },
    {
      text: 'Lemah',
      value: 'Lemah'
    },
    {
      text: 'Pucat',
      value: 'Pucat'
    },
    {
      text: 'Sesak',
      value: 'Sesak'
    },
    {
      text: 'Sianosis',
      value: 'Sianosis'
    },
  ],

  configStatusGSCE: [
    {
      text: '(1): tidak ada respon meskipun sudah dirangsan',
      value: 1
    },
    {
      text: '(2): dengan memberikan rangsangan nyeri, misalnya menekan kuku jari',
      value: 2
    },
    {
      text: '(3): dengan rangsang suara, dilakukan dengan menyuruh pasien untuk',
      value: 3
    },
    {
      text: '(4): spontan membuka mata dengan sendirinya tanpa dirangsang',
      value: 4
    },
  ],

  configStatusGSCV: [
    {
      text: '(1): tidak ada respon',
      value: 1
    },
    {
      text: '(2): suara tanpa arti (mengerang)',
      value: 2
    },
    {
      text: '(3): mengucapkan kata-kata yang tidak jelas',
      value: 3
    },
    {
      text: '(4): bingung, berbicara mengacau (berulang-ulang), disorientasi tempat dan waktu',
      value: 4
    },
    {
      text: '(5): orientasi baik, bicaranya jelas',
      value: 5
    },
  ],

  configStatusGSCM: [
    {
      text: '(1): tidak ada respon',
      value: 1
    },
    {
      text: '(2): ekstensi abnormal',
      value: 2
    },
    {
      text: '(3): fleksi abnormal',
      value: 3
    },
    {
      text: '(4): menarik anggotak gerak karena rangsangan nyeri',
      value: 4
    },
    {
      text: '(5): menarik anggota gerak karena sentuhan',
      value: 5
    },
    {
      text: '(6): bergerak spontan',
      value: 6
    },
  ],
  
  configStatusKonjungtiva: [
    {
      text : "Anemis",
      value : "Anemis",
    },
    {
      text: "Tidak Anemis",
      value: "Tidak Anemis",
    },
  ],
  
  configStatusSklera: [
    {
      text : "Ikterik",
      value : "Ikterik",
    },
    {
      text: "Tidak Ada Masalah",
      value: "Tidak Ada Masalah",
    },
  ],

  configStatusHidung: [
    {
      text: "Epitaktis",
      value: "Epitaktis",
    },
    {
      text : "Epitaktis",
      value : "Asimetris",
    },
    {
      text: "Tidak Ada Masalah",
      value: "Tidak Ada Masalah",
    },
  ],
  
  configStatusParu: [
    {
      text: "Simetris",
      value: "Simetris",
    },
    {
      text: "Tidak Simetris",
      value: "Tidak Simetris",
    },
    {
      text: "Tidak Ada Masalah",
      value: "Tidak Ada Masalah",
    },
    {
      text: "Lain-lain",
      value: "Lain-lain",
    },
  ],

  
  configStatusRespirasi: [
    {
      text: "Tidak ada Kesulitan",
      value: "Tidak ada Kesulitan",
    },
    {
      text: "Dyspnea",
      value: "Dyspnea",
    },
    {
      text: "Wheezing",
      value: "Wheezing",
    },
    {
      text: "Hemaptoe",
      value: "Hemaptoe",
    },
    {
      text: "Ronchi di paru kanan / kiri",
      value: "Ronchi di paru kanan / kiri",
    }
  ],

  configStatusJantung: [
    {
      text: "Nyeri Dada",
      value: "Nyeri Dada",
    },
    {
      text: "Aritmia",
      value: "Aritmia",
    },
    {
      text: "Trakikardi",
      value: "Trakikardi",
    },
    {
      text: "Bradikardi",
      value: "Bradikardi",
    },
    {
      text: "Tidak ada masalah",
      value: "Tidak ada masalah",
    },
    {
      text: "Lain-lain",
      value: "Lain-lain",
    }
  ],

  configKondisiAbdomen: [
    {
      text: "Distesi",
      value: "Distesi",
    },
    {
      text: "Asites",
      value: "Asites",
    },
    {
      text: "Lain-lain",
      value: "Lain-lain",
    }
  ],
  
  configAkaral: [
    {
      text: "Dingin",
      value: "Dingin",
    },
    {
      text: "Hangat",
      value: "Hangat",
    }
  ],

  statusRujukanUGD: [
    {
      text : 'Semua Rujukan',
      value : 'ALL'
    },
    {
      text: 'Datang Sendiri',
      value: 1
    },
    {
      text: 'Rujukan Dokter',
      value: 2
    },
    {
      text: 'Rujukan Rumah Sakit',
      value: 3
    },
    {
      text: 'Rujukan Puskesmas',
      value: 4
    },
  ],

  statusUGDRujukan: [
    {
      text: 'Datang Sendiri',
      value: 1
    },
    {
      text: 'Rujukan Dokter',
      value: 2
    },
    {
      text: 'Rujukan Rumah Sakit',
      value: 3
    },
    {
      text: 'Rujukan Puskesmas',
      value: 4
    },
  ],

  statusPersetujuanPasien: [
    {
      text: "Pasien",
      value: "P"
    },
    {
      text: "Penanggungjawab / Wali Hukum",
      value: "W"
    }
  ],

  izinYesNo: [{
      text: "Mengizinkan",
      value: "Y"
    },
    {
      text: "Tidak Mengizinkan",
      value: "N"
    }
  ],

  monthName: [
    { text: "Januari", value: "01" },
    { text: "Februari", value: "02" },
    { text: "Maret", value: "03" },
    { text: "April", value: "04" },
    { text: "Mei", value: "05" },
    { text: "Juni", value: "06" },
    { text: "July", value: "07" },
    { text: "Agustus", value: "08" },
    { text: "September", value: "09" },
    { text: "Oktober", value: "10" },
    { text: "November", value: "11" },
    { text: "Desember", value: "12" }
  ],

  rsTime: [
    { text: "Waktu Sesuai Server", value: "server" },
    { text: "Waktu Sesuai RS", value: "rs" }
  ],

  caraDaftar: [
    { text: "Pendaftaran Offline", value: "BO-REG" },
    { text: "Pendaftaran Online", value: "E-REG" },
    { text: "ICS", value: "ICS" },
    { text: "Moblie JKN", value: "JKN" },
  ],

  typeFilterBelumLayan: [
    {
      text: "Antrean Belum Dilayani",
      value : "AB"
    },
    {
      text: "Antrean Pertanggal",
      value: "AP"
    },
    {
      text: "Antrean Perkode Booking",
      value: "AK"
    }
  ],

  typeDashboard: [{
      text: "Dashboard Pertanggal",
      value: "DT"
    },
    {
      text: "Dashboard Perbulan",
      value: "DB"
    }
  ],

  typeHariLibur: [{
      text: "Hari Libur Tiap Tahun",
      value: "Tetap"
    },
    {
      text: "Hari libur hanya tahun ini",
      value: "Pertahun"
    }
  ],

  // UGD START
  // Risiko Luka Decubitus

  rldKondisiFisik: [{
      text: "Baik",
      value: 4
    },
    {
      text: "Sedang",
      value: 3
    },
    {
      text: "Buruk",
      value: 2
    },
    {
      text: "Sangat Buruk",
      value: 1
    },
  ],
  rldKesadaran: [{
      text: "Komposmentis",
      value: 4
    },
    {
      text: "Apatis",
      value: 3
    },
    {
      text: "Konfus / Soporus",
      value: 2
    },
    {
      text: "Stupor / Koma",
      value: 1
    },
  ],
  rldAktivitas: [
  {
    text: "Ambulan",
    value: 4
  }, {
    text: "Ambulan dengan Bantuan",
    value: 3
  }, {
    text: "Hanya Bisa Duduk",
    value: 2
  }, {
    text: "Tiduran",
    value: 1
  },
  ],
  rldMobilitas: [{
    text: "Bergerak Bebas",
    value: 4
  }, {
    text: "Sedikit Terbatas",
    value: 3
  }, {
    text: "Sangat Terbatas",
    value: 2
  }, {
    text: "Tidak Bisa Bergerak",
    value: 1
  }],
  rldInkontinensia:[{
    text: "Tidak",
    value: 4
  }, {
    text: "Kadang-Kadang",
    value: 3
  }, {
    text: "Sering Inkontinensia Urin",
    value: 2
  }, {
    text: "Inkontinensia Urin dan Alvi",
    value: 1
  }],


  // UGD EDOMSON Perawat
  edUmur:[{
    text: "< 50 tahun",
    value: 8
  }, {
    text: "50 - 79 tahun",
    value: 10
  }, {
    text: "> 80 tahun",
    value: 25
  }],
  
  edStatusMental:[{
    text: "Kesadaran / Orientasi Baik",
    value: 4
  }, {
    text: "Ada Kecemasan / Agitasi",
    value: 12
  }, {
    text: "Kadang-kadang Bingung",
    value: 13
  },{
    text: "Confusion / Disorientasi",
    value: 14
  }],
  
  edPolaBAB:[{
    text: "Mampu Mengontrol BAB/BAK dengan Baik",
    value: 8
  }, {
    text: "Memakai DC",
    value: 11
  }, {
    text: "BAK / BAB Dibantu",
    value: 10
  },{
    text: "Inkontinensia, Nocturia, Frekuensi",
    value: 12
  }],
  
  edPengobatan:[{
    text: "Tidak Memakai Obat-obatan",
    value: 10
  }, {
    text: "Memakai Obat-obatan Jantung",
    value: 8
  }, {
    text: "Memakai Obat Psikotropika dan Anti Depresan",
    value: 10
  },{
    text: "Memakai Obat Anestesi, Nyeri dalam 24 Jam Terakhir",
    value: 12
  }],

  edDiagnosis:[{
    text: "Gangguan Bipolar / Schizophrenia",
    value: 10
  }, {
    text: "Penyalahgunaan Alkohol / Zat Lain",
    value: 8
  }, {
    text: "Gangguan Depresi Mayor",
    value: 10
  },{
    text: "Gangguan Demensia / Dilirium",
    value: 12
  }],

  edAmbulasi:[{
    text: "Mandiri",
    value: 7
  }, {
    text: "Mobilisasi Pakai Alat Bantu",
    value: 8
  }, {
    text: "Vertigo / Hipotensi Orthostatic / Kelemahan Anggota Gerak",
    value: 10
  },{
    text: "Tidak Siap / Lupa Keterbatasan Diri",
    value: 15
  }],
  
  edNutrisi:[{
    text: "Intake Makan / Minum Kurang dalam 24 Jam",
    value: 12
  }, {
    text: "Makan Minum Cukup",
    value: 0
  }],

  edRiwayatjatuh:[{
    text: "Tidak Ada Riwayat Jatuh",
    value: 8
  }, {
    text: "Ada Riwayat Jatuh Dalam 3 Bulan Terakhir",
    value: 14
  }],

  // humpty dumpy
  "hdUmur": [{
      "text": "Lebih dari 13 Tahun",
      "value": 1
    },
    {
      "text": "7 - 13 Tahun",
      "value": 2
    },
    {
      "text": "3 - 7 Tahun",
      "value": 3
    },
    {
      "text": "Kurang dari 3 Tahun",
      "value": 4
    }
  ],
  "hdJK": [{
      "text": "Perempuan",
      "value": 1
    },
    {
      "text": "Laki-Laki",
      "value": 2
    }
  ],
  "hdDiagnosis": [{
      "text": "Diagnosa Lain",
      "value": 1
    },
    {
      "text": "Gangguan Perilaku",
      "value": 2
    },
    {
      "text": "Perubahan dalam Oksigenasi, Anoreksia, Pusing",
      "value": 3
    },
    {
      "text": "Penyakit Neurologi",
      "value": 4
    }
  ],
  "hdGangguanKognitif": [{
      "text": "Orientasi Baik Terhadap Diri Sendiri",
      "value": 1
    },
    {
      "text": "Lupa Akan Adanya Keterbatasan",
      "value": 2
    },
    {
      "text": "Tidak Menyadari Keterbatasan Dirinya",
      "value": 3
    }
  ],
  "hdFaktorLingkungan": [{
      "text": "Pasien di Area Rawat Jalan",
      "value": 1
    },
    {
      "text": "Pasien Diletakkan di Bed",
      "value": 2
    },
    {
      "text": "Pasien Menggunakan Alat Bantu Bayi",
      "value": 3
    },
    {
      "text": "Riwayat Jatuh Saat Toddler (1-3 Tahun)",
      "value": 4
    }
  ],
  "hdPembedahan": [{
      "text": "Lebih dari 48 Jam",
      "value": 1
    },
    {
      "text": "Dalam Waktu 48 Jam",
      "value": 2
    },
    {
      "text": "Dalam Waktu 24 Jam",
      "value": 3
    }
  ],
  "hdPenggunaanObat": [{
      "text": "Obat Lain",
      "value": 1
    },
    {
      "text": "Penggunaan salah satu dari obat tertulis",
      "value": 2
    },
    {
      "text": "Sedative, Obat Hypnosis, Barbbiturate, Fenotazine, Anti Depresan, Pencahar, Deuretic, Narcose",
      "value": 3
    }
  ],

  // skala nyeri ugd
  snRiwayatJatuh : [{
    text: "Tidak",
    value: 0
  }, {
    text: "Ya",
    value: 25
  }],
  
  snDiagnosa : [{
    text: "Tidak",
    value: 0
  }, {
    text: "Ya",
    value: 25
  }],

  snAlatBantuJalan : [{
    text: "Bedrest Dibantu Perawat/Bidan",
    value: 0
  }, {
    text: "Penopang/Tongkat/Walker",
    value: 15
  }, {
    text: "Furniture",
    value: 30
  }],

  snPakaiInfus : [{
    text: "Tidak",
    value: 0
  }, {
    text: "Ya",
    value: 25
  }],

  snCaraJalan : [{
    text: "Normal/Bedrest/Immobilisasi",
    value: 0
  }, {
    text: "Lemah",
    value: 15
  }, {
    text: "Terganggu",
    value: 30
  }],

  snStatusMental : [{
    text: "Orientasi Sesuai Kemampuan",
    value: 0
  }, {
    text: "Lupa Keterbatasan Diri",
    value: 15
  }],

  // pengkajian tingkat kesadaran
  ksResponseMata : [
    {
      text: "Dapat Membuka Mata Secara Spontan",
      value: 4
    }, {
      text: "Dapat Membuka Mata Sebagai Respons Terhadap Perintah Verbal",
      value: 3
    },{
      text: "Membuka Mata Sebagai Respons Terhadap Rangsangan Nyeri atau Paksaan",
      value: 2
    },{
      text: "Tidak Dapat Membuka Mata Sama Sekali Terhadap Rangsangan Apapun",
      value: 1
    }
  ],

  ksResponseVerbal : [
    {
      text: "Pasien Sadar dan Merespons Pertanyaan dengan Benar",
      value: 5
    },{
      text: "Pasien Bingung atau Disorientasi",
      value: 4
    }, {
      text: "Pasien Memberikan Respons Tidak Sesuai dengan Instruksi atau Pertanyaan",
      value: 3
    },{
      text: "Pasien Hanya Dapat Mengeluarkan Suara yang Tidak Dapat Dipahami",
      value: 2
    },{
      text: "Pasien Tidak Memberikan Respons Verbal Terhadap Rangsangan Apapun",
      value: 1
    }
  ],

  ksResponseMotorik : [
    {
      text: "Pasien Dapat Melakukan Gerakan Sesuai Perintah",
      value: 6
    },{
      text: "Pasien Dapat Mengarahkan Gerakan Ke Sumber Rangsangan",
      value: 5
    },{
      text: "Terjadi Fleksi atau Pasien Menarik atau Menghindari Rangsangan Nyeri",
      value: 4
    }, {
      text: "Pasien Menunjukkan Gerakan Fleksi Sebagai Respons Terhadap Rangsangan",
      value: 3
    },{
      text: "Pasien Menunjukkan Gerakan Ekstensi Sebagi Respons Terhadap Rangsangan",
      value: 2
    },{
      text: "Pasien Tidak Memberikan Respons Motorik Terhadap Rangsangan Apa Pun",
      value: 1
    }
  ],

  // sydney

  syTransfer : [
    {
      text: "Mandiri(boleh menggunakan alat bantu jalan)",
      value: 0
    },
    {
      text: "Memerlukan sedikit bantuan (1 orang) atau dalam pengawasan",
      value: 1
    },
    {
      text: "Memerlukan bantuan yang nyata (2 orang)",
      value: 2
    },
    {
      text: "Tidak dapat duduk seimbang, perlu bantuan total",
      value: 3
    },
  ],

  syMobilitas : [
    {
      text: "Mandiri (boleh menggunakan alat bantu jalan)",
      value: 0
    },
    {
      text: "Berjalan dengan bantuan 1 orang (verbal/fisik)",
      value: 1
    },
    {
      text: "Menggunakan kursi roda",
      value: 2
    },
    {
      text: "Immobilisasi",
      value: 3
    },
  ],  

  // penurunan berat ugd

  cfPenurunanBeratUGD: [
    {
      text: "Tidak ada penurunan berat badan",
      value: "T",
      number: 0,
    },
    {
      text: "Tidak yakin/Tidak tahu/Baju terasa longgar",
      value: "TY",
      number: 2,
    },
  ],
  cfPenurunanBeratUGDLain: [
    {
      text: "1-5kg",
      value : 1,
    }, {
      text: "6-10kg",
      value: 2,      
    }, {
      text: "11-15kg",
      value: 3,
    }, {
      text: ">15kg",
      value: 4,
    },
  ],
  configVitalSignUGD: [{
      'value': 'auod_vs_kepala',
      'label': 'Kepala',
      'isOpen': false
    },
    {
      'value': 'auod_vs_mata',
      'label': 'Mata',
      'isOpen': false
    },
    {
      'value': 'auod_vs_hidung',
      'label': 'Hidung',
      'isOpen': false
    },
    {
      'value': 'auod_vs_rambut',
      'label': 'Rambut',
      'isOpen': false
    },
    {
      'value': 'auod_vs_bibir',
      'label': 'Bibir',
      'isOpen': false
    },
    {
      'value': 'auod_vs_gigi',
      'label': 'Gigi Geligi',
      'isOpen': false
    },
    {
      'value': 'auod_vs_lidah',
      'label': 'Lidah',
      'isOpen': false
    },
    {
      'value': 'auod_vs_langit',
      'label': 'Langit - Langit',
      'isOpen': false
    },
    {
      'value': 'auod_vs_leher',
      'label': 'Leher',
      'isOpen': false
    },
    {
      'value': 'auod_vs_tenggorokan',
      'label': 'Tenggorokan',
      'isOpen': false
    },
    {
      'value': 'auod_vs_tonsil',
      'label': 'Tonsil',
      'isOpen': false
    },
    {
      'value': 'auod_vs_dada',
      'label': 'Dada',
      'isOpen': false
    },
    {
      'value': 'auod_vs_payudara',
      'label': 'Payudara',
      'isOpen': false
    },
    {
      'value': 'auod_vs_punggung',
      'label': 'Punggung',
      'isOpen': false
    },
    {
      'value': 'auod_vs_perut',
      'label': 'Perut',
      'isOpen': false
    },
    {
      'value': 'auod_vs_genital',
      'label': 'Genital',
      'isOpen': false
    },
    {
      'value': 'auod_vs_anus',
      'label': 'Anus/Dubur',
      'isOpen': false
    },
    {
      'value': 'auod_vs_lengan_atas',
      'label': 'Lengan Atas',
      'isOpen': false
    },
    {
      'value': 'auod_vs_lengan_bawah',
      'label': 'Lengan Bawah',
      'isOpen': false
    },
    {
      'value': 'auod_vs_jari_tangan',
      'label': 'Jari Tangan',
      'isOpen': false
    },
    {
      'value': 'auod_vs_kuku',
      'label': 'Kuku Tangan',
      'isOpen': false
    },
    {
      'value': 'auod_vs_sendi_tangan',
      'label': 'Pesendian Tangan',
      'isOpen': false
    },
    {
      'value': 'auod_vs_tungkai_atas',
      'label': 'Tungkai Atas',
      'isOpen': false
    },
    {
      'value': 'auod_vs_tungkai_bawah',
      'label': 'Tungkai Bawah',
      'isOpen': false
    },
    {
      'value': 'auod_vs_jari_kaki',
      'label': 'Jari Kaki',
      'isOpen': false
    },
    {
      'value': 'auod_vs_kuku_kaki',
      'label': 'Kuku Kaki',
      'isOpen': false
    },
    {
      'value': 'auod_vs_persendian_kaki',
      'label': 'Persendian Kaki',
      'isOpen': false
    },
    {
      'value': 'auod_vs_saraf',
      'label': 'Saraf',
      'isOpen': false
    }
  ],


  // airways
  bebasTidakOpt: [{
      text: 'Bebas',
      value: 'B'
    },
    {
      text: 'Tidak Bebas',
      value: 'TB'
    },
  ],
  
  positifNegatifOpt: [
    {
      text: 'Positif',
      value: 'Positif'
    },
    {
      text: 'Negatif',
      value: 'Negatif'
    },
  ],

  suaraNapasOpt: [{
      text: 'Normal',
      value: 'Normal'
    },
    {
      text: 'Stidor',
      value: 'Stidor'
    },
    {
      text: 'Tidak ada suara napas',
      value: 'Tidak ada suara napas'
    },
    {
      text: 'Lain-lain',
      value: 'L'
    },
  ],

  // circulation
  normalTidakNormalOpt: [{
      text: 'Normal',
      value: 'N'
    },
    {
      text: 'Tidak Normal',
      value: 'TN'
    },
  ],

  nadikapilerOpt: [{
      text: '< 2',
      value: '< 2'
    },
    {
      text: '> 2',
      value: '> 2'
    },
  ],

  kuatLemahOpt: [{
      text: 'Kuat',
      value: 'Kuat'
    },
    {
      text: 'Lemah',
      value: 'Lemah'
    },
  ],

  // breathing
  polaNapasOpt: [{
      text: 'Apnea',
      value: 'Apnea'
    },
    {
      text: 'Bradipnea',
      value: 'Bradipnea'
    },
    {
      text: 'Sesak',
      value: 'Sesak'
    },
    {
      text: 'Takipnea',
      value: 'Takipnea'
    },
    {
      text: 'Orthopnea',
      value: 'Orthopnea'
    },
  ],
  
  bunyiNapasOpt: [{
      text: 'Vesikuler',
      value: 'Vesikuler'
    },
    {
      text: 'Wheezing',
      value: 'Wheezing'
    },
    {
      text: 'Stidor',
      value: 'Stidor'
    },
    {
      text: 'Ronchi',
      value: 'Ronchi'
    }
  ],
  
  tandaDistressOpt: [{
      text: 'Penggunaan otot bantu',
      value: 'Penggunaan otot bantu'
    },
    {
      text: 'Retraksi dada/interkosta',
      value: 'Retraksi dada/interkosta'
    },
    {
      text: 'Cuping hidung',
      value: 'Cuping hidung'
    }
  ],
   
  jenisNapasOpt: [{
      text: 'Pernafasan Dada',
      value: 'Pernafasan Dada'
    },
    {
      text: 'Pernafasan Perut',
      value: 'Pernafasan Perut'
    },
    {
      text: 'Lain-lain',
      value: 'L'
    }
  ],

  disabilty: [
    {
      text: 'Pupil Anisokor',
      value: 'Pupil Anisokor'
    }, {
      text: 'Kejang',
      value: 'Kejang'
    }
  ],
  
  pasienSetuju: [
    {
      text: 'Tindakan disetujui',
      value: 'Y'
    }, {
      text: 'Tindakan ditolak',
      value: 'N'
    }
  ],
  
  persetujuanTindakan: [
    {
      text: 'Pasien',
      value: 'P'
    }, {
      text: 'Penanggungjawab Pasien',
      value: 'PJ'
    }
  ],

  tindakLanjutUGD:[
    {
      text: 'Rujuk',
      value: 'RU'
    }, {
      text: 'Rawat Jalan',
      value: 'RJ'
    }, {
      text: 'Rawat Inap',
      value: 'RI'
    },
  ],
  
  tindakLanjutRawatJalan:[
    {
      text: 'Keluhan Membaik',
      value: 'KM'
    }, {
      text: 'Meninggal di UGD',
      value: 'MU'
    }, {
      text: 'DOA/Meninggal',
      value: 'DOA'
    }, {
      text: 'Menolak Rawat Inap',
      value: 'MR'
    }
  ],

  pasienMasukKeRuangan: [{
    text: "Ruang Rawat Inap",
    value: 'PRI'
  }, {
    text: "Ruang ICU",
    value: 'RICU'
  }],

  transportasiPulangUGD: [
    {
      text: "Kendaraan Pirbadi",
      value: 'Kendaraan Pirbadi'
    },
    {
      text: "Ambulans",
      value: 'Ambulans'
    },
    {
      text: "Ambulans Jenazah",
      value: 'Ambulans Jenazah'
    },
  ],

  pendidikanPasienPulang: [
    {
      text: "Makan / Minum Obat Rutin",
      value: 'Makan / Minum Obat Rutin'
    },
    {
      text: "Atur Pola Makan",
      value: 'Atur Pola Makan'
    },
    {
      text: "Menjaga Kebersihan Luka",
      value: 'Menjaga Kebersihan Luka'
    },
    {
      text: "Datang Kembali Untuk Kontrol",
      value: 'Datang Kembali Untuk Kontrol'
    },
    {
      text: "Lain-Lain",
      value: 'L'
    },
  ],


  dnrDibuatAtas: [
    {
      text: "Atas Permintaan Pasien",
      value: "Pasien",
    },
    {
      text: "Atas Permintaan Keluarga",
      value: "Keluarga",
    },
    {
      text: "Atas Advis DPJP",
      value: "DPJP",
    },
  ],
  ttdCreatedBy: [
    {
      text: "TTD Basah/Manual",
      value: "Manual"
    },
    {
      text: "TTD Digital",
      value: "Digital"
    }
  ],


  // partus
  pengisianPartus: [
    {
      text: "Isi di Aplikasi",
      value: "App",
    },
    {
      text: "Isi Manual",
      value: "Manual",
    },
  ],
  
  partusPersalinan: [
    {
      text: "Rumah Ibu",
      value: "Rumah Ibu",
    },
    {
      text: "Puskesmas",
      value: "Puskesmas",
    },
    {
      text: "Klinik Swasta",
      value: "Klinik Swasta",
    },
    {
      text: "Lainnya",
      value: "L",
    },
  ],
  
  partusK1: [
    {
      text: "Partograf melewati garis waspada",
      value: "Partograf melewati garis waspada",
    },
    {
      text: "Lainnya",
      value: "L",
    },
  ],
  
  partusK2Pendamping: [
    {
      text: "Suami",
      value: "Suami",
    },
    {
      text: "Keluarga",
      value: "Keluarga",
    },
    {
      text: "Teman",
      value: "Teman",
    },
    {
      text: "Dukun",
      value: "Dukun",
    },
    {
      text: "Tidak Ada",
      value: "Tidak Ada",
    },
  ],

  partusK2GawatJanin: [
    {
      text: "Miringkan ibu ke sisi kiri",
      value: "Miringkan ibu ke sisi kiri",
    },
    {
      text: "Minta Ibu Menarik Napas",
      value: "Minta Ibu Menarik Napas",
    },
    {
      text: "Episiotomi",
      value: "Episiotomi",
    },
  ],

  partusK2Distosia: [
    {
      text: "Manuver McRobert Ibu Merangkang",
      value: "Manuver McRobert Ibu Merangkang",
    },
    {
      text: "Lainnya",
      value: "L",
    },
  ],

  partusK3Tindakan: [
    {
      text: "Mengeluarkan Secara manual",
      value: "Mengeluarkan Secara manual",
    },
    {
      text: "Merujuk",
      value: "Merujuk",
    },
    {
      text: "Tindakan lain",
      value: "L",
    },
  ],
  
  partusK3AtoniaUteri: [
    {
      text: "Kompresi Bimanual Internal",
      value: "Kompresi Bimanual Internal",
    },
    {
      text: "Metil Ergometrin 0,2 mg IM",
      value: "Metil Ergometrin 0,2 mg IM",
    },
    {
      text: "Oksitosin Drip",
      value: "Oksitosin Drip",
    },
    {
      text: "Lainnya",
      value: "L",
    },
  ],

  partusBBLStatus: [
      {
        text: "Mengeringkan",
        value: "Mengeringkan",
      },
      {
        text: "Menghangatkan",
        value: "Menghangatkan",
      },
      {
        text: "Bebaskan Jalan Napas",
        value: "Bebaskan Jalan Napas",
      },
      {
        text: "Simulasi rangsang aktif",
        value: "Simulasi rangsang aktif",
      },
      {
        text: "Cacat Bawaan",
        value: "Cacat Bawaan",
      },
      {
        text: "Lainnya",
        value: "L",
      },
    ],

    partusMateri: [
      {
        text: "Semua Nifas",
        value: "Semua Nifas",
      },
      {
        text: "Breast Care",
        value: "Breast Care",
      },
      {
        text: "ASI",
        value: "ASI",
      },
      {
        text: "Perawatan tali Pusar",
        value: "Perawatan tali Pusar",
      },
      {
        text: "KL",
        value: "KL",
      },
      {
        text: "Gizi",
        value: "Gizi",
      },
      {
        text: "Imunisasi",
        value: "Imunasasi",
      },
    ],
  // partus end

  // BBL

    bblKulit: [
      {
        text: "Ikteris",
        value: "Ikteris",
      },
      {
        text: "Pucat",
        value: "Pucat",
      },
      {
        text: "Dingin",
        value: "Dingin",
      },
      {
        text: "Odema",
        value: "Odema",
      },
      {
        text: "Sianotik",
        value: "Sianotik",
      },
      {
        text: "Lainnya",
        value: "L",
      },
    ],

    bblGerak: [{
        text: "Atas",
        value: "Atas",
      },
      {
        text: "Batas",
        value: "Batas",
      },
      {
        text: "Jari-jari",
        value: "Jari-jari",
      },
      {
        text: "Ujung 2 jari",
        value: "Ujung 2 jari",
      },
      {
        text: "Rajah Jari - 2 Tangan",
        value: "Rajah Jari - 2 Tangan",
      },
      {
        text: "Lainnya",
        value: "L",
      },
    ],

    pernahTidakPernah: [{
        value: "TP",
        text: "Tidak Pernah"
      },
      {
        value: "P",
        text: "Pernah"
      }
    ],

    JenisDarah: [
      {
        value: "Whole Blood",
        text: "Whole Blood"
      },
      {
        value: "Packed Red Cell",
        text: "Packed Red Cell"
      },
      {
        value: "WE",
        text: "WE"
      },
      {
        value: "FFP",
        text: "FFP"
      },
      {
        value: "PRP",
        text: "PRP"
      },
      {
        value: "Trombosit",
        text: "Trombosit"
      },
      {
        value: "Cryo",
        text: "Cryo"
      },
    ],

    // ugd perawat tindak lanjut

    ugdTindakanLanjut: [{
       value: "L",
       text: "Pilih dari yang ada"
     },
     {
       value: "N",
       text: "Buat baru"
     },
    ],
    ugdAssesmenTindakan: [
      {
        value: "Memberikan oksigen",
        text: "Memberikan oksigen"
      },
      {
        value: "Melakukan Perekaman EKG",
        text: "Melakukan Perekaman EKG"
      },
      {
        value: "Memasang bedside monitor",
        text: "Memasang bedside monitor"
      },
      {
        value: "Melakukan pemeriksaan cek gula darah acak",
        text: "Melakukan pemeriksaan cek gula darah acak"
      },
      {
        value: "Melakukan pemasangan infus sesuai dengan advis dokter",
        text: "Melakukan pemasangan infus sesuai dengan advis dokter"
      },
      {
        value: "Melaksanakan pemberian injeksi",
        text: "Melaksanakan pemberian injeksi"
      },
      {
        value: "Melakukan pemasangan kateter tetap",
        text: "Melakukan pemasangan kateter tetap"
      },
      {
        value: "Melakukan suction",
        text: "Melakukan suction"
      },
      {
        value: "Melakukan penjahitan luka",
        text: "Melakukan penjahitan luka"
      },
      {
        value: "Melakukan perawatan luka",
        text: "Melakukan perawatan luka"
      },
      {
        value: "Melakukan ekstraksi kuku",
        text: "Melakukan ekstraksi kuku"
      },
      {
        value: "Melakukan incici pada...",
        text: "Melakukan incici pada..."
      },
      {
        value: "Memasang gelang identitas warna...",
        text: "Memasang gelang identitas warna..."
      },
      {
        value: "Memasang gelang penanda alergi",
        text: "Memasang gelang penanda alergi"
      },
      {
        value: "Memasang bidai/spalk",
        text: "Memasang bidai/spalk"
      },
      {
        value: "Melakukan pengambilan benda asing/corpal",
        text: "Melakukan pengambilan benda asing/corpal"
      },
      {
        value: "Melakukan irigasi mata",
        text: "Melakukan irigasi mata"
      },
      {
        value: "Melakukan irigasi telinga",
        text: "Melakukan irigasi telinga"
      },
      {
        value: "Melakukan lavement",
        text: "Melakukan lavement"
      },
      {
        value: "Melakukan pelepasan kateter tetap",
        text: "Melakukan pelepasan kateter tetap"
      },
      {
        value: "Memberikan obat... dengan nebulizer",
        text: "Memberikan obat... dengan nebulizer"
      },
      {
        value: "Memasang NGT",
        text: "Memasang NGT"
      },
      {
        value: "Melakukan perawatan luka combutio",
        text: "Melakukan perawatan luka combutio"
      },
      {
        value: "Memberikan obat melalui titrasi syring pump dengan dosis",
        text: "Memberikan obat melalui titrasi syring pump dengan dosis"
      },
      {
        value: "Melakukan CTG",
        text: "Melakukan CTG"
      },
      {
        value: "Memfasilitasi pasien untuk pemeriksaan radiologi",
        text: "Memfasilitasi pasien untuk pemeriksaan radiologi"
      },
      {
        value: "Free Text",
        text: "Free Text"
      },
    ],
    tindakLanjutUGDDokter:[
      {
        value: "RI",
        text: "Rawat Inap"
      },
      {
        value: "RU",
        text: "Rujuk"
      },
      {
        value: "RJ",
        text: "Rawat Jalan"
      },
      {
        value: "O",
        text: "Observasi"
      },
      {
        value: "P",
        text: "Melahirkan"
      },
    ],
    layakTidakLayakOpt:[
      {
        value: "L",
        text: "Layak"
      },
      {
        value: "TL",
        text: "Tidak Layak"
      },
    ],
    tindakLanjutFarmasi: [{
        value: "Lanjut, aturan pakai sama (LS)",
        text: "Lanjut, aturan pakai sama (LS)"
      },
      {
        value: "Lanjut, aturan pakai berubah (LB)",
        text: "Lanjut, aturan pakai berubah (LB)"
      },
      {
        value: "Stop (S)",
        text: "Stop (S)"
      },
      {
        value: "Ganti (G)",
        text: "Ganti (G)"
      },
    ],

    rujukanMasukStatus: [
      {
        text: "Menunggu",
        value: "M"
      },
      {
        text: "Diterima",
        value: "A"
      },
      {
        text: "Ditolak",
        value: "T"
      },
    ],
    rujukanKeluarStatus: [
      {
        text: "Menunggu",
        value: "M"
      },
      {
        text: "Menunggu Response",
        value: "MR"
      },
      {
        text: "Diterima",
        value: "A"
      },
      {
        text: "Ditolak",
        value: "T"
      },
    ],
    responRujukan: [
      {
        text: "Terima",
        value: "A"
      },
      {
        text: "Tolak",
        value: "T"
      },
    ],
    alasanRujukan:[
      {
        "value": "1",
        "text": "Penanganan / perawatan lanjut pasien (medik)"
      },
      {
        "value": "2a",
        "text": "Sarana dan prasarana (Ruang Perawatan Biasa)"
      },
      {
        "value": "2b",
        "text": "Sarana dan prasarana (Ruang intensive ( ICU, CVCU/ICCU, NICU, PICU, HCU))"
      },
      {
        "value": "2c",
        "text": "Sarana dan prasarana (Ruang Isolasi)"
      },
      {
        "value": "3",
        "text": "Dokter spesialis / sub spesialis tidak tersedia"
      },
      {
        "value": "4",
        "text": "Tindakan khusus"
      }
    ],
    
    "nilaiKekuatan": [
    {
      "text": "Tidak ada kontraksi otot sama sekali.",
      "value": "0"
    },
    {
      "text": "Kontraksi	otot	minimal	terasa/teraba	pada	otot bersangkutan tanpa menimbulkan gerakan.",
      "value": 1
    },
    {
      "text": "dapat menggerakkan anggota gerak tetatpi tidak dapat melawan gravitasi (menggeser anggota gerak).",
      "value": 2
    },
    {
      "text": "Dapat melakukan ROM secara penuh dengan melawan gaya berat (gravitasi), tetapi tidak dapat melawan tahanan.",
      "value": 3
    },
    {
      "text": "Dapat melakukan Range Of Motion (ROM) secara penuh dan dapat melawan tahanan ringan",
      "value": 4
    },
    {
      "text": "Kekuatan	otot	normal	dimana	seluruh gerakan dapat dilakukan otot dengan tahanan maksimal dari proses yang dilakukan berulang - ulang tanpa menimbulkan kelelahan.",
      "value": 5
    }
  ],

  Terlaksana: [
    {
      "text": "Terlaksana",
      "value": 1
    },
    {
      "text": "Belum Terlaksana",
      "value": 0
    },
  ],
  
  mrKasusPenyakit: [
    {
      "text": "Internis/Dalam",
      "value": 1
    },
    {
      "text": "Pediatric/Anak",
      "value": 2
    },
    {
      "text": "Obsgyn/Kandungan dan Kebidanan",
      "value": 3
    },
    {
      "text": "Surgery/Bedah",
      "value": 4
    },
    {
      "text": "Syaraf",
      "value": 5
    },
    {
      "text": "Jantung",
      "value": 6
    },
    {
      "text": "Paru-Paru",
      "value": 7
    },
    {
      "text": "Orthopedi/Bedah Tulang",
      "value": 8
    },
    {
      "text": "THT/Telinga Hidung Tenggorokan",
      "value": 9
    },
    {
      "text": "Kulit Kelamin",
      "value": 10
    },
    {
      "text": "Gigi dan Mulut",
      "value": 11
    },
    {
      "text": "Mata",
      "value": 12
    },
    {
      "text": "Urologi",
      "value": 13
    },
  ],

  KontrolRujuk: [
    {
      "text": "Rujukan",
      "value": "Y"
    },
    {
      "text": "Kontrol",
      "value": "N"
    },
  ],

  triasePenempatan : [
    {
      "text": "Ruang Resusitasi",
      "value": "Ruang Resusitasi"
    },
    {
      "text": "Ruang Observasi",
      "value": "Ruang Observasi"
    },
    {
      "text": "Ruang Tindakan",
      "value": "Ruang Tindakan"
    },
    {
      "text": "Ruang Isolasi",
      "value": "Ruang Isolasi"
    }
  ],
  
  triaseSkrining : [
    {
      "text": "Preventif",
      "value": "Preventif"
    },
    {
      "text": "Paliatif",
      "value": "Paliatif"
    },
    {
      "text": "Kuratif",
      "value": "Kuratif"
    },
    {
      "text": "Rehabilitatif",
      "value": "Rehabilitatif"
    }
  ],


  typeICU: [{
      "text": "ICU",
      "value": "ICU"
    },
    {
      "text": "PICU/NICU",
      "value": "PCNC"
    },
  ],
  icuTTV: [{
      "text": "Nadi < 40 kali/menit, >150 kali/menit",
      "value": "Nadi < 40 kali/menit, >150 kali/menit"
    },
    {
      "text": "TD sistolik arteri < 80 mmHg atau 20 mmHg dibawah TD pasien sehari-hari",
      "value": "TD sistolik arteri < 80 mmHg atau 20 mmHg dibawah TD pasien sehari-hari"
    },
    {
      "text": "Map < 60mmHg",
      "value": "Map < 60mmHg"
    },
    {
      "text": "TD diastolic arteri >120mmHg dengan oedem paru, hipertensi",
      "value": "TD diastolic arteri >120mmHg dengan oedem paru, hipertensi"
    },
    {
      "text": "Pasien dengan gagal nafas, RR > 35 x/menit",
      "value": "Pasien dengan gagal nafas, RR > 35 x/menit"
    },
    {
      "text": "AMI (Acut Myocard Infark)",
      "value": "AMI (Acut Myocard Infark)"
    },
    {
      "text": "Aritmia jantung yang mengancam jiwa",
      "value": "Aritmia jantung yang mengancam jiwa"
    }
  ],
  icuNilaiLab: [
    {
      "text": "Natrium serum <110 mEq/L/ >170mEq/L",
      "value": "Natrium serum <110 mEq/L/ >170mEq/L"
    },
    {
      "text": "Kalium serum <2.0 mEq/L / 7.0 mEq/L",
      "value": "Kalium serum <2.0 mEq/L / 7.0 mEq/L"
    },
    {
      "text": "GDS >800 mg/dl",
      "value": "GDS >800 mg/dl"
    },
    {
      "text": "Kalsium serum > 15mg/dl",
      "value": "Kalsium serum > 15mg/dl"
    },
    {
      "text": "Kadar toksik obat atau bahan kimia lain dengan gangguan hemodinamik dan neurologis",
      "value": "Kadar toksik obat atau bahan kimia lain dengan gangguan hemodinamik dan neurologis"
    }
  ],
  icuNilaiRadiologi: [
    {
      "text": "Perdarahan vaskuler otak, kontusio atau perdarahan subarachnoid dengan penurunan kesadaran atau tanda defisit neurologis fokal",
      "value": "Perdarahan vaskuler otak, kontusio atau perdarahan subarachnoid dengan penurunan kesadaran atau tanda defisit neurologis fokal"
    },
    {
      "text": "Ruptur organ dalam, kandung kemih, hepar,varises esophagusatau uterus dengan hemodinamik tidak stabil",
      "value": "Ruptur organ dalam, kandung kemih, hepar,varises esophagusatau uterus dengan hemodinamik tidak stabil"
    },
    {
      "text": "Diseksi aneurisma aorta",
      "value": "Diseksi aneurisma aorta"
    },
  ],
  icuPemeriksaanFisik: [{
      "text": "Pupil anisokor pada pasien tidak sadar",
      "value": "Pupil anisokor pada pasien tidak sadar"
    },
    {
      "text": "Luka besar >10% BSA",
      "value": "Luka besar >10% BSA"
    },
    {
      "text": "Anuria",
      "value": "Anuria"
    },
    {
      "text": "Obstruksi jalan nafas",
      "value": "Obstruksi jalan nafas"
    },
    {
      "text": "Koma",
      "value": "Koma"
    },
    {
      "text": "Kejang berlanjut",
      "value": "Kejang berlanjut"
    },
    {
      "text": "Sianosis",
      "value": "Sianosis"
    },
    {
      "text": "Temponade jantung",
      "value": "Temponade jantung"
    },
  ],

  nicuNilaiTTV: [{
      "text": "Nadi <80 atau >180 x/menit",
      "value": "Nadi <80 atau >180 x/menit"
    },
    {
      "text": "Tekanan darah sistolik  arteri <50 mmHg atau 20 mmHg dibawah tekanan darah normal bayi menurut masa gestasi",
      "value": "Tekanan darah sistolik  arteri <50 mmHg atau 20 mmHg dibawah tekanan darah normal bayi menurut masa gestasi"
    },
    {
      "text": "Frekuensi nafas <30  atau >90 x/menit",
      "value": "Frekuensi nafas <30  atau >90 x/menit"
    },
  ],
  
  nicuNilaiRadiografi: [{
      "text": "Perdarahan intracranial dengan penurunan kesadaran atau tanda deficit neurologis",
      "value": "Perdarahan intracranial dengan penurunan kesadaran atau tanda deficit neurologis"
    },
    {
      "text": "Hernia diafragma",
      "value": "Hernia diafragma"
    }
  ],

  },
  wording:{
    detectUpdate: 'Perubahan data belum di simpan.'
  },
  toolsBasicCK: [
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'link',
    'bulletedList',
    'numberedList',
    '|',
    'undo',
    'redo'
  ],
  dataShownOptions: [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '30', value: 30 },
  ],
  dataShown:25
}
export default Config